import PlaqueTypes from '../assets/frametraits/plaque_types_ids.json'
import { ID_PREFIX_PLAQUE, CHANCE_OF_PLAQUE } from './FrameSvgConstants'

export const plaqueStyleForTrait = (plaqueStyle = 0) => {
  if (plaqueStyle <= CHANCE_OF_PLAQUE) {
    const type =
      PlaqueTypes[
        ID_PREFIX_PLAQUE.concat(plaqueStyle % Object.keys(PlaqueTypes).length)
      ]

    return {
      type: type
    }
  }
  return { type: null }
}
