import ERC165 from '../assets/contracts/ERC165.json'

export const supportsInterface = (web3, contractAddress, interfaceId) => {
  return getContractInstance(web3, contractAddress)
    .methods.supportsInterface(interfaceId)
    .call()
}

const getContractInstance = (web3, contractAddress) => {
  return new web3.eth.Contract(ERC165.abi, contractAddress)
}
