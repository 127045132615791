export const signMessage = (web3, account, message) => {
  return new Promise((resolve, reject) => {
    web3.eth.personal
      .sign(message, account)
      .then(signed => {
        resolve({
          account: account,
          message: message,
          signature: signed
        })
      })
      .catch(e => {
        reject(e)
      })
  })
}
