import { replaceIpfsIfNeeded, getSvgCodeFromFile } from '../lib/helpers.js'
import FrameTypes from '../assets/frametraits/frame_types_ids.json'
import RimTypes from '../assets/frametraits/rim_types_ids.json'
import CornerTypes from '../assets/frametraits/corner_types_ids.json'
import EffectTypes from '../assets/frametraits/effect_types_ids.json'
import AnimationTypes from '../assets/frametraits/animation_types_ids.json'
import PlaqueContentTypes from '../assets/frametraits/plaque_content_types_ids.json'
import { frameStyleForTrait } from '../traitsystem/FrameStyleForTrait'
import { cornerStyleForTrait } from '../traitsystem/CornerStyleForTrait'
import { rimStyleForTrait } from '../traitsystem/RimStyleForTrait'
import { effectStyleForTrait } from '../traitsystem/EffectStyleForTrait'
import { animationStyleForTrait } from '../traitsystem/AnimationStyleForTrait'
import { scrollingRimStyleForTrait } from '../traitsystem/ScrollingRimForTrait'
import { plaqueStyleForTrait } from '../traitsystem/PlaqueStyleForTrait'
import { plaqueContentStyleForTrait } from '../traitsystem/PlaqueContentsStyleForTrait'
import {
  IPFS_FOLDER_FRAMES,
  IPFS_FOLDER_CORNERS,
  IPFS_FOLDER_RIMS,
  IPFS_FOLDER_EFFECTS,
  IPFS_FOLDER_ANIMATIONS,
  IPFS_FOLDER_PLAQUES,
  SIGNATURE_ID
} from '../traitsystem/FrameSvgConstants'
import getSvgSignature from './getSvgSignature'
var convert = require('color-convert')

export const decodeFrameTraits = async (
  traitHash,
  abortSignal = null
) => {
  if (traitHash.startsWith('0x')) {
    traitHash = traitHash.slice(2) // remove 0x
  }

  if (abortSignal && abortSignal.aborted) {
    throw new Error('Aborted')
  }
  // ee / 9c / ec / c0 / 57 / d9a9ce / 918cd5 / 5adef7 / 142ffc5cad27a0a95061825673e977790b5d'
  // first byte = style 256 / 160
  const frameTrait = frameStyleForTrait(
    parseInt('0x'.concat(traitHash.slice(0, 2)))
  )
  const frameStyle = FrameTypes[frameTrait]

  const frameFileUrl = replaceIpfsIfNeeded(
    IPFS_FOLDER_FRAMES.concat('/').concat(frameStyle.filename)
  )

  if (abortSignal && abortSignal.aborted) {
    throw new Error('Aborted')
  }
  const tiledPiece = await getSvgCodeFromFile(frameFileUrl)

  // second byte = corner
  const corner = cornerStyleForTrait(
    frameStyle,
    parseInt('0x'.concat(traitHash.slice(2, 4)))
  )

  const cornerStyle = CornerTypes[corner]

  const cornerFileUrl = replaceIpfsIfNeeded(
    IPFS_FOLDER_CORNERS.concat('/').concat(cornerStyle.filename)
  )
  if (abortSignal && abortSignal.aborted) {
    throw new Error('Aborted')
  }
  const cornerPiece = await getSvgCodeFromFile(cornerFileUrl)

  // third byte = rims
  const rims = rimStyleForTrait(parseInt('0x'.concat(traitHash.slice(4, 6))))

  let tileRim = null
  let cornerRim = null
  if (rims) {
    const rimStyle = RimTypes[rims]
    const rimsFrameFileUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_RIMS.concat('/').concat(rimStyle.frame)
    )
    const rimsCornerUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_RIMS.concat('/').concat(rimStyle.corner)
    )
    if (abortSignal && abortSignal.aborted) {
      throw new Error('Aborted')
    }
    tileRim = await getSvgCodeFromFile(rimsFrameFileUrl)
    cornerRim = frameStyle.cornerRimsForStyle
      ? await getSvgCodeFromFile(rimsCornerUrl)
      : null
  }
  // fourth byte = effect
  const effect = effectStyleForTrait(
    parseInt('0x'.concat(traitHash.slice(6, 8)))
  )

  let tileEffect = null
  let cornerEffect = null

  if (effect) {
    const effectStyle = EffectTypes[effect]
    const effectFrameFileUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_EFFECTS.concat('/').concat(effectStyle.frame)
    )
    const effectCornerUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_EFFECTS.concat('/').concat(effectStyle.corner)
    )
    if (abortSignal && abortSignal.aborted) {
      throw new Error('Aborted')
    }
    tileEffect = await getSvgCodeFromFile(effectFrameFileUrl)
    if (abortSignal && abortSignal.aborted) {
      throw new Error('Aborted')
    }
    cornerEffect = await getSvgCodeFromFile(effectCornerUrl)
  }

  // fifth byte = plaque with text / plaque with signature / none

  let plaque

  const plaqueTrait = plaqueStyleForTrait(
    parseInt('0x'.concat(traitHash.slice(8, 10)))
  )

  if (plaqueTrait.type) {
    const plaqueFileUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_PLAQUES.concat('/').concat(plaqueTrait.type.filename)
    )
    if (abortSignal && abortSignal.aborted) {
      throw new Error('Aborted')
    }
    plaque = await getSvgCodeFromFile(plaqueFileUrl)
  }
  // sixth byte = animation
  let animation = null
  const animationTrait = animationStyleForTrait(
    parseInt('0x'.concat(traitHash.slice(10, 12)))
  )

  if (animationTrait) {
    const animStyle = AnimationTypes[animationTrait]
    const animFileUrl = replaceIpfsIfNeeded(
      IPFS_FOLDER_ANIMATIONS.concat('/').concat(animStyle.filename)
    )
    if (abortSignal && abortSignal.aborted) {
      throw new Error('Aborted')
    }
    animation = await getSvgCodeFromFile(animFileUrl)
  }

  // seventh byte = scrolling rim
  const scrollingRimTrait = scrollingRimStyleForTrait(
    parseInt('0x'.concat(traitHash.slice(12, 14)))
  )

  // eighth/9th/10th byte = main color
  const rawMainColor = '#'.concat(traitHash.slice(14, 20))

  const mainColor = '#'.concat(
    convert.keyword.hex(convert.hex.keyword(rawMainColor))
  )

  // 11th/12th/13th byte = accent color
  const rawAccentColor = '#'.concat(traitHash.slice(20, 26))

  const accentColor = '#'.concat(
    convert.keyword.hex(convert.hex.keyword(rawAccentColor))
  )
  // 14th/15th/16th byte = secondary accent color
  const rawSecondaryAccentColor = '#'.concat(traitHash.slice(26, 32))
  const secondaryAccentColor = '#'.concat(
    convert.keyword.hex(convert.hex.keyword(rawSecondaryAccentColor))
  )
  // 17th byte = plaque content
  let plaqueContents
  const plaqueContentsTrait = plaqueContentStyleForTrait(
    plaqueTrait,
    parseInt('0x'.concat(traitHash.slice(32, 34)))
  )

  if (plaqueContentsTrait.contents) {
    if (plaqueContentsTrait.contents === SIGNATURE_ID) {
      plaqueContents = getSvgSignature(traitHash)
    } else {
      const contentsUrl = replaceIpfsIfNeeded(
        IPFS_FOLDER_PLAQUES.concat('/').concat(
          PlaqueContentTypes[plaqueContentsTrait.contents].filename
        )
      )
      if (abortSignal && abortSignal.aborted) {
        throw new Error('Aborted')
      }
      plaqueContents = await getSvgCodeFromFile(contentsUrl)
    }
  }

  return {
    main: mainColor,
    accent: accentColor,
    secondaryAccent: secondaryAccentColor,
    tiledPiece: tiledPiece,
    cornerPiece: cornerPiece,
    tileRim: tileRim,
    cornerRim: cornerRim,
    tileEffect: tileEffect,
    cornerEffect: cornerEffect,
    plaque: plaque,
    plaqueContents: plaqueContents,
    scrollingRimAnimation: scrollingRimTrait.animated,
    scrollingRimAnimationReverse: scrollingRimTrait.reverse,
    animation: animation
  }
}
