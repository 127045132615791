export const connectSolanaWallet = () => {
  const isPhantomInstalled = window.solana && window.solana.isPhantom

  if (!isPhantomInstalled) {
    throw new Error('Phantom wallet is not installed - cannot sign message')
  }

  return new Promise((resolve, reject) => {
    const provider = window.solana

    provider
      .connect()
      .then(({ publicKey }) => {
        console.log('Connected to solana')
        resolve({ library: provider, address: publicKey.toString() })
      })
      .catch(error => {
        console.log('Error connecting to solana:', error)
        reject(error)
      })
  })
}
