import { setApproveTokenTransfer } from '../datasource/Erc721DataSource'

export const setErc721TransferApprovedFromAddress = async (
  web3,
  erc721TokenContractAddress,
  account,
  spenderAddress,
  approved
) => {
  return setApproveTokenTransfer(
    web3,
    erc721TokenContractAddress,
    account,
    spenderAddress,
    approved
  )
}
