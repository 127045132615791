import React, { useState } from 'react'
import { WEB3_READONLY_RPCS } from '../../../lib/constants'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Icon } from '@mui/material'
export default function ChainSelector ({ onChainSelected, sx }) {
  const [selectedChain, setSelectedChain] = useState(WEB3_READONLY_RPCS[1])

  const handleChange = event => {
    console.log(event.target.value)
    setSelectedChain(event.target.value)
    onChainSelected && onChainSelected(event.target.value)
  }

  return (
    <Paper
      component='form'
      sx={{
        p: '12px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...(sx
          ? {
              ...sx
            }
          : {})
      }}
    >
      <FormControl
        sx={{
          m: '0px 12px',
          flex: 1
        }}
      >
        <InputLabel id='demo-simple-select-helper-label'>Chain</InputLabel>
        <Select
          labelId='demo-simple-select-helper-label'
          id='demo-simple-select-helper'
          value={selectedChain}
          label='Chain'
          renderValue={selected => (
            <MenuItem key={selected.id} sx={{ pointerEvents: 'none', p: 0 }}>
              <ListItemIcon>
                <Icon>
                  <img alt={selected.name} src={selected.logo} />
                </Icon>
              </ListItemIcon>
              <ListItemText>{selected.name}</ListItemText>
            </MenuItem>
          )}
          onChange={handleChange}
        >
          {Object.entries(WEB3_READONLY_RPCS).map(([key, chainInfo]) => (
            <MenuItem
              key={chainInfo.id}
              value={chainInfo}
              sx={{ flexDirection: 'row' }}
            >
              <ListItemIcon>
                <Icon>
                  <img alt={chainInfo.name} src={chainInfo.logo} />
                </Icon>
              </ListItemIcon>
              <ListItemText>{chainInfo.name}</ListItemText>
            </MenuItem>
          ))}
          {/* <MenuItem
            key={WEB3_READONLY_RPCS[1].id}
            value={WEB3_READONLY_RPCS[1]}
          >
            <ListItemIcon>
              <Icon>
                <img
                  alt={WEB3_READONLY_RPCS[1].name}
                  src={WEB3_READONLY_RPCS[1].logo}
                />
              </Icon>
            </ListItemIcon>
            <ListItemText>{WEB3_READONLY_RPCS[1].name}</ListItemText>
          </MenuItem> */}
        </Select>
      </FormControl>
    </Paper>
  )
}
