import { getErc721TokenMetadataForToken } from './GetErc721TokenMetadataForTokenUsecase'
import { getErc1155TokenMetadataForToken } from './GetErc1155TokenMetadataForTokenUsecase'
import { getIsContractErc1155 } from './GetIsContractErc1155Usecase'
import { getIsContractErc721 } from './GetIsContractErc721Usecase'

export const getTokenMetadata = async (
  web3,
  contractAddress,
  tokenId,
  chainId = 1,
  preferOnchain = false
) => {
  let metadata

  if (preferOnchain) {
    metadata = await getOnChainMetadataFromContract(
      web3,
      contractAddress,
      tokenId
    )
  } else {
    const endpoint = getEndpointForToken(contractAddress, tokenId, chainId)
    const response = await fetch(endpoint)
    metadata = await response.json()
  }

  if (!metadata) {
    throw new Error('Error loading metadata')
  }

  return metadata
}

const getOnChainMetadataFromContract = async (
  web3,
  contractAddress,
  tokenId
) => {
  let isErc1155 = false
  try {
    isErc1155 = await getIsContractErc1155(web3, contractAddress)
  } catch (e) {
    console.error(e)
  }
  if (!isErc1155) {
    const isErc721 = await getIsContractErc721(web3, contractAddress)

    if (!isErc721) {
      throw new Error('Unsupported contract type - must be ERC115 or ERC721')
    }
  }

  let metadata
  try {
    metadata = isErc1155
      ? await getErc1155TokenMetadataForToken(web3, contractAddress, tokenId)
      : await getErc721TokenMetadataForToken(web3, contractAddress, tokenId)
  } catch (e) {
    console.error(
      'Failed to fetch metadata for ' + contractAddress + ' ' + tokenId,
      e
    )
  }

  if (!metadata) {
    throw new Error('Error loading metadata')
  }

  return metadata
}

const getEndpointForToken = (contractAddress, tokenId, chainId = 1) => {
  return `https://frames-api.murall.art/v1/tokens/${contractAddress}/${tokenId}/metadata?chain=${chainId}`
}
