import React from 'react'
import styled from 'styled-components'
import { scroller } from 'react-scroll'
import Fab from '@mui/material/Fab'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ScrollDownFab = ({ section }) => {
  return (
    <Button
      onClick={() => scroller.scrollTo(section, { smooth: true })}
      color='primary'
      aria-label='scroll to next section'
    >
      <KeyboardArrowDownIcon />
    </Button>
  )
}

const Button = styled(Fab)`
  position: absolute;
  left: 50%;
  bottom: ${({ theme }) => theme.spacing(3)};
  transform: translate(-50%, 0);
`

export default ScrollDownFab
