import React from 'react'
import styled from 'styled-components'
import SUPPORTED_WALLETS from '../../../../lib/connectors/supportedWallets'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../../../lib/connectors'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import AccountAddress from '../Address'

const AccountView = ({ onChangeClick }) => {
  return (
    <Container>
      <ConnectionInfo>
        <ConnectionName />
        <ConnectionButtons onChange={onChangeClick} />
      </ConnectionInfo>
      <AccountAddress />
    </Container>
  )
}

const ConnectionName = () => {
  const { connector } = useWeb3React()
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const name = Object.keys(SUPPORTED_WALLETS).filter(
    (k) =>
      SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
  ).map((k) => SUPPORTED_WALLETS[k].name)[0]

  return (
    <Typography color='text.secondary' variant='caption'>Connected with {name}</Typography>
  )
}

const ConnectionButtons = ({ onChange }) => {
  const { connector } = useWeb3React()
  return (
    <ConnectionButtonsContainer>
      <ConnectionButton
        onClick={onChange}
        variant='outlined'
        size='small'
        startIcon={<LoopOutlinedIcon />}
      >Change</ConnectionButton>
      {connector !== injected && (
        <ConnectionButton
        onClick={() => connector.close()}
        variant='outlined'
        size='small'
        startIcon={<CancelOutlinedIcon />}
      >Disconnect</ConnectionButton>
    )}
  </ConnectionButtonsContainer>
  )
}

const Container = styled.div`
  display: flex;  
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.default[400]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 15px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(2)};
`

const ConnectionInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

const ConnectionButtonsContainer = styled.div`
  margin-left: auto;
  width: min-content;
`

const ConnectionButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.secondary};
  border-radius: 15px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export default AccountView
