import { getTokenMetadata } from './GetTokenMetadataUseCase'
import { processTokenMetadata } from './ProcessTokenMetadataUseCase'

export const getAndProcessTokenMetadata = async (
  web3,
  contractAddress,
  tokenId,
  chainId = 1,
  fetchDimensions = false,
  preferAnimation = false
) => {
  let metadata

  if (contractAddress) {
    try {
      metadata = await getTokenMetadata(web3, contractAddress, tokenId, chainId)
      console.log('metadata', metadata)
    } catch (e) {
      console.error(
        'error fetching metadata for ' +
          contractAddress +
          ' token ' +
          tokenId +
          ' chain ' +
          chainId,
        e
      )
    }

    if (!metadata) {
      throw new Error(
        'Error fetching metadata for ' +
          contractAddress +
          ' token ' +
          tokenId +
          ' chain ' +
          chainId
      )
    }
    return processTokenMetadata(metadata, fetchDimensions, preferAnimation)
  } else {
    return processTokenMetadata(null, fetchDimensions, preferAnimation)
  }
}
