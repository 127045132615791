import {
  SVG_DEFS_TAG_START,
  SVG_DEFS_TAG_END,
  FRAME_RIM_HEIGHT,
  FRAME_RIM_WIDTH,
  CORNER_PIECE_HEIGHT,
  CORNER_PIECE_ID,
  TILE_PIECE_ID,
  PLAQUE_ID,
  PLAQUE_HEIGHT,
  PLAQUE_WIDTH,
  ANIMATION_ID
} from '../traitsystem/FrameSvgConstants'

// this filter does nothing - it just makes it easier to reference the animation at all times
const DEFAULT_ANIMATION = ` <filter id="${ANIMATION_ID}" x="0%" y="0%" width="100%" height="100%" 
filterUnits="objectBoundingBox" primitiveUnits="objectBoundingBox" color-interpolation-filters="sRGB">
<feComponentTransfer>
    <feFuncR type="table" tableValues="0 1"/>
    <feFuncG type="table" tableValues="0 1"/>
    <feFuncB type="table" tableValues="0 1"/>
</feComponentTransfer>
</filter>`

export const constructBaseSvgString = (
  colour,
  accentColor,
  accentSecondaryColor,
  tiledPiece = '',
  cornerPiece = '',
  tileRim = '',
  cornerRim = '',
  tileEffect = '',
  cornerEffect = '',
  scrollingRimAnimation = false,
  scrollingRimAnimationReverse = false,
  animation = '',
  plaque = '',
  plaqueContents = ''
) => {
  const tiledFramePiece = `${tiledPiece}${tileRim}${tileEffect}`
  const cornerFramePiece = `${cornerPiece}${cornerRim}${cornerEffect}`

  return `${defineStyles(colour, accentColor, accentSecondaryColor)}
  ${SVG_DEFS_TAG_START}
  ${animation ? animation : DEFAULT_ANIMATION}
  ${defineCornerDetail(cornerFramePiece)}
  ${defineTiledFrameFiller(
    tiledFramePiece,
    scrollingRimAnimation,
    scrollingRimAnimationReverse
  )}
  ${definePlaque(plaque, plaqueContents)}
  ${SVG_DEFS_TAG_END}`
}

const defineStyles = (
  mainColor = '#00ff00',
  accentColor = '#0000ff',
  accentSecondaryColor = '#00ff00'
) => {
  return `<style type="text/css">
        .prefix_main_colour{fill:${mainColor};}
        .gradient_main_colour{stop-color:${mainColor};}
        .prefix_accent_colour{fill:${accentColor};} 
        .gradient_accent_colour{stop-color:${accentColor};} 
        .prefix_accent_secondary_colour{fill:${accentSecondaryColor};} 
        .gradient_accent_secondary_colour{stop-color:${accentSecondaryColor};} 
        </style>`
}

const defineWrappedPattern = (
  id,
  width,
  height,
  code,
  animate = false,
  reverse = false
) => {
  return `<pattern id="${id}" x="0" y="0" width="${width}" height="${height}" patternUnits="userSpaceOnUse" style="overflow:visible">
              ${code}
              ${animate &&
                `<animateTransform attributeType="xml"
              attributeName="patternTransform"
              type="translate" from="0 0" to="${
                reverse ? width : -width
              } 0" begin="0"
              dur="10s" repeatCount="indefinite"/>`}
            </pattern>`
}

const defineWrappedGroup = (id, width, height, code) => {
  return `<g id="${id}" x="0" y="0" width="${width}" height="${height}" style="overflow:visible">
              ${code}
            </g>`
}

const definePlaque = (plaque, plaqueContents = '') => {
  return defineWrappedGroup(
    PLAQUE_ID,
    PLAQUE_HEIGHT,
    PLAQUE_WIDTH,
    `<svg>${plaque}${plaqueContents}</svg>`
  )
}

const defineTiledFrameFiller = (
  tilePieceSvg,
  scrollingRimAnimation = false,
  scrollingRimAnimationReverse = false
) => {
  return defineWrappedPattern(
    TILE_PIECE_ID,
    FRAME_RIM_WIDTH,
    FRAME_RIM_HEIGHT + 1,
    tilePieceSvg,
    scrollingRimAnimation,
    scrollingRimAnimationReverse
  )
}

const defineCornerDetail = cornerPieceSvg => {
  return defineWrappedGroup(
    CORNER_PIECE_ID,
    CORNER_PIECE_HEIGHT,
    CORNER_PIECE_HEIGHT,
    cornerPieceSvg
  )
}
