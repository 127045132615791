import React from 'react'
import styled from 'styled-components'

const WidthLimiter = ({ children, maxWidth = 1200, className }) => {
  return (
    <Container maxWidth={maxWidth} className={className}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  display: flex;
  margin: auto;
  position: relative;
`

export default WidthLimiter
