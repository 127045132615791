import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Box, Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import FramesLogo from '../../../assets/images/logos/FRAME_small.png'
import Fade from 'react-reveal/Fade'
import { orange } from '@mui/material/colors'
var convert = require('color-convert')

const BG =
  'linear-gradient(30deg, #000000 0%, #040504 16%, #080b08 33%, #0c0f0c 50%, #101310 66%, #121612 83%, #151915 100%)'
const GRADIENT = 'linear-gradient( 135deg, #FCCF31 10%, #F55555 100%)'

export default function FrameDetails (props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [styleAttribute, setStyleAttribute] = useState(null)
  const [cornerAttribute, setCornerAttribute] = useState(null)
  const [otherAttributes, setOtherAttributes] = useState(null)

  useEffect(() => {
    async function onFrameMetadataUpdated () {
      const style = props.frameMetadata.attributes.find(
        attribute => attribute.trait_type === 'Style'
      )
      setStyleAttribute(style)
      const corner = props.frameMetadata.attributes.find(
        attribute => attribute.trait_type === 'Corner'
      )
      setCornerAttribute(corner)
      const other = props.frameMetadata.attributes.filter(
        attribute =>
          attribute.trait_type !== 'Style' &&
          attribute.trait_type !== 'Corner' &&
          attribute.trait_type !== 'Framed Content' &&
          attribute.trait_type !== 'Framed Content Chain' &&
          attribute.trait_type !== 'Framing Method'
      )
      setOtherAttributes(other)
    }

    onFrameMetadataUpdated()
  }, [props.frameMetadata])

  const createTextRow = (index, key, value, breakpoints) => (
    <Grid item {...breakpoints} key={index}>
      <Fade right cascade>
        <Box
          sx={{
            flex: 1,
            display: 'block',
            borderRadius: '5px',
            background: BG + ' padding-box,' + GRADIENT + ' border-box',
            border: '2px solid transparent',
            pt: { xs: '12px', sm: '24px' },
            pb: { xs: '12px', sm: '24px' },
            pl: { xs: '12px' },
            pr: { xs: '12px' },
            boxShadow: theme.shadows[4]
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 500
            }}
            variant={isMobile ? 'subtitle2' : 'body2'}
            color={orange[300]}
            align={'center'}
          >
            {key}
          </Typography>
          <Box
            sx={{
              mt: { xs: '6px', sm: '12px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {key.toLowerCase() === 'color' ||
            key.toLowerCase() === 'accent' ||
            key.toLowerCase() === 'secondary accent' ? (
              <Typography
                sx={{
                  pr: { xs: '12px' },
                  fontFamily: 'Roboto',
                  fontWeight: 300,
                  color: '#' + convert.keyword.hex(value)
                }}
                variant={isMobile ? 'h6' : 'h5'}
                align={'center'}
              >
                {`\u2B24`}
              </Typography>
            ) : (
              ''
            )}
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 300
              }}
              variant={isMobile ? 'h6' : 'h5'}
              noWrap
              color='inherit'
              align={'center'}
            >
              {value}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Grid>
  )

  return (
    <Card
      elevation={0}
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {}),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            alt='Frames'
            src={FramesLogo}
            variant='square'
            sx={{
              width: theme => theme.spacing(4),
              height: theme => theme.spacing(4)
            }}
          />
        }
        title={
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 300
            }}
            align={'center'}
            variant={isMobile ? 'h5' : 'h4'}
          >
            {props.frameMetadata.name}
          </Typography>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
          padding: '0px',
          width: '100%'
        }}
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          spacing={2}
          sx={{
            paddingBottom: { xs: '12px', sm: '24px', md: '36px' }
          }}
        >
          {styleAttribute &&
            createTextRow(0, styleAttribute.trait_type, styleAttribute.value, {
              xs: 12
            })}
          {cornerAttribute &&
            createTextRow(
              1,
              cornerAttribute.trait_type,
              cornerAttribute.value,
              {
                xs: 12
              }
            )}
          {otherAttributes &&
            otherAttributes.map((attribute, index) => {
              return createTextRow(
                index,
                attribute.trait_type,
                attribute.value,
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4
                }
              )
            })}
        </Grid>
      </CardContent>
    </Card>
  )
}
