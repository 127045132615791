import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import styled from 'styled-components'
import DefaultContainer from '../../DefaultContainer'
import MountedContentPreview from '../mounting/MountedContentPreview'
import FrameDetails from './FrameDetails'
import FramedContentDetails from './FramedContentDetails'
import Fade from 'react-reveal/Fade'

import Paper from '@mui/material/Paper'

const LeftContainer = styled.div`
  display: inline-flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  min-width: 0;
  padding: 0;
  margin: 0;
  min-height: 0;
  height: 100%;
`
const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  min-height: 0;
  margin: 0;
  height: 100%;
`

export default function FramePageContent ({ frame, frameMetadata }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        alignItems: 'stretch',
        margin: '0',
        width: '100%',
        height: '100%'
      }}
    >
      <LeftContainer>
        <MountedContentPreview
          sx={{
            width: '100%',
            pt: '24px',
            pl: '24px',
            pb: '24px',
            flex: 1
          }}
          selectedFrame={frame}
          selectedFrameMetadata={frameMetadata}
        />
      </LeftContainer>
      <RightContainer>
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flex: 'none',
            flexDirection: 'column',
            alignItems: 'stretch',
            minWidth: 0,
            maxWidth: '800px',
            paddingLeft: '24px',
            paddingRight: '24px',
            height: '100%',
            overflow: 'auto',
            boxShadow: theme.shadows[4],
            backgroundColor: 'rgba(0,0,0,0.8)'
          }}
        >
          <DefaultContainer
            sx={{
              flex: 1,
              display: 'block flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center'
            }}
          >
            <FrameDetails
              sx={{ width: '100%', flex: 1, pt: '24px' }}
              frameMetadata={frameMetadata}
            />
            <FramedContentDetails
              sx={{ width: '100%' }}
              frame={frame}
              frameMetadata={frameMetadata}
            />
          </DefaultContainer>
        </Paper>
      </RightContainer>
    </Paper>
  )
}
