import { CHANCE_OF_SCROLLING_FRAME } from './FrameSvgConstants'

export const scrollingRimStyleForTrait = (scrollingRimTrait = 0) => {
  return {
    animated: scrollingRimTrait <= CHANCE_OF_SCROLLING_FRAME,
    reverse:
      scrollingRimTrait < CHANCE_OF_SCROLLING_FRAME &&
      scrollingRimTrait % 2 === 0
  }
}
