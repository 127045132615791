import React, { useState, useEffect, useRef } from 'react'
import { Parallax } from 'react-parallax'
import { SpringValue } from 'react-spring'
import Carousel from '../components/common/Carousel'

export default function FramesParallaxCarousel (props) {
  const [swiperInstance, setSwiperInstance] = useState(null)
  const [percentage, setPercentage] = useState({ percentage: 0 })
  const percentageRef = useRef(percentage)
  const currentAnimationRef = useRef(null)
  const [targetPercentage, setTargetPercentage] = useState(0)

  useEffect(() => {
    async function onLoad () {
      if (swiperInstance) {
        // workaround for the swiper not displaying loop data on load
        swiperInstance.slideToLoop(swiperInstance.realIndex, 1, true)
      }
    }

    onLoad()
  }, [props.images, swiperInstance]) // Empty array ensures that effect is only run on mount

  useEffect(() => {
    const handleScroll = () => {
      if (swiperInstance) {
        let velocity = 0
        if (currentAnimationRef.current) {
          velocity = currentAnimationRef.current.velocity
          currentAnimationRef.current.stop()
        } else {
          currentAnimationRef.current = new SpringValue(targetPercentage)
        }

        currentAnimationRef.current.start({
          from: percentageRef.current.percentage,
          to: targetPercentage,
          config: { duration: 50, velocity: velocity },
          onChange: (result, spring, item) => {
            percentageRef.current.percentage = result

            if (swiperInstance) {
              swiperInstance.params.slidesOffsetBefore = result
              swiperInstance.update()
            }
          }
        })
      }
    }
    handleScroll()
  }, [targetPercentage, swiperInstance])

  const createImage = (imgPath, index) => (
    <img
      src={imgPath}
      alt=''
      style={{
        position: 'absolute',
        width: `100%`,
        height: '100%',
        objectFit: 'contain',
        padding: 0
      }}
    />
  )

  return (
    <Parallax
      renderLayer={percentage => {
        setTargetPercentage(percentage * props.parallaxValue)
        return <div />
      }}
    >
      <Carousel
        {...props}
        loop={true}
        loopAdditionalSlides={2}
        centeredSlides={true}
        onSwiper={swiper => {
          setSwiperInstance(swiper)
        }}
        contents={props.images}
        onCreateContent={createImage}
      />
    </Parallax>
  )
}
