import EthLogo from '../assets/images/logos/logo_eth.svg'
import BscLogo from '../assets/images/logos/logo_bsc.svg'
import PolygonLogo from '../assets/images/logos/logo_polygon.svg'
import SolanaLogo from '../assets/images/logos/logo_solana.svg'
import FantomLogo from '../assets/images/logos/logo_fantom.svg'
import ArbitrumOneLogo from '../assets/images/logos/logo_arbitrum_one.svg'
import AvalancheLogo from '../assets/images/logos/logo_avalanche.svg'

export const FramesContractDetails = {
  id: '0x785B0CEb43d35ff40e24Ef863a0C7d400a96e3A2',
  name: 'Frames by MurAll',
  symbol: 'FRAMES',
  type: 'ERC721'
}
export const SupportedChain = {
  Ethereum: 1,
  Ropsten: 3,
  Rinkeby: 4,
  Goerli: 5
}

export const SUPPORTED_CHAINS = [
  SupportedChain.Ethereum,
  SupportedChain.Ropsten,
  SupportedChain.Rinkeby,
  SupportedChain.Goerli
]

export const FALLBACK_PROVIDER_NAME = 'network fallback read only'

export const CHAIN_INFO = {
  [SupportedChain.Ethereum]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 }
  },
  [SupportedChain.Rinkeby]: {
    explorer: 'https://rinkeby.etherscan.io/',
    label: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby ETH', symbol: 'rinkETH', decimals: 18 }
  },
  [SupportedChain.Ropsten]: {
    explorer: 'https://ropsten.etherscan.io/',
    label: 'Ropsten',
    nativeCurrency: { name: 'Ropsten ETH', symbol: 'ropETH', decimals: 18 }
  },
  [SupportedChain.Goerli]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Görli',
    nativeCurrency: { name: 'Görli ETH', symbol: 'görETH', decimals: 18 }
  }
}

export const WEB3_READONLY_RPCS = {
  1: {
    id: 1,
    name: 'Ethereum',
    rpc:
      'https://eth-mainnet.alchemyapi.io/v2/qJd-V4xaqrcxqg32aZ_Hv8lSyo0jLy8z',
    logo: EthLogo
  },
  137: {
    id: 137,
    name: 'Polygon',
    rpc: 'https://polygon-rpc.com',
    logo: PolygonLogo
  },
  56: {
    id: 56,
    name: 'Binance Smart Chain',
    rpc: 'https://bsc-dataseed.binance.org/',
    logo: BscLogo
  },
  999: {
    id: 999,
    name: 'Solana',
    logo: SolanaLogo,
    requiresSecondSignature: true
  }
  // 250: {
  //   id: 250,
  //   name: 'Fantom',
  //   rpc: 'https://rpc.ftm.tools',
  //   logo: FantomLogo
  // },
  // 42161: {
  //   id: 42161,
  //   name: 'Arbitrum One',
  //   rpc: 'https://arb1.arbitrum.io/rpc',
  //   logo: ArbitrumOneLogo
  // },
  // 43114: {
  //   id: 43114,
  //   name: 'Avalanche',
  //   rpc: 'https://api.avax.network/ext/bc/C/rpc',
  //   logo: AvalancheLogo
  // }
  // 11297108109: { id: 11297108109, name: 'Palm', rpc: 'https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b' },
}

export const CHAIN_INFO_BY_NAME = {
  Polygon: WEB3_READONLY_RPCS[137],
  Binance: WEB3_READONLY_RPCS[56],
  Ethereum: WEB3_READONLY_RPCS[1],
  Solana: WEB3_READONLY_RPCS[999]
}
