import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = 'https://frames-api.murall.art'

export const Api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/v1`
  }),
  endpoints: builder => ({
    getFrame: builder.query({
      query: id => ({
        url: `frames/${id}`,
        method: 'GET'
      })
    }),
    postMountFrame: builder.query({
      query: ({
        frameId,
        tokenId,
        tokenAddress,
        width,
        height,
        signer,
        signature,
        chain,
        signer2,
        signature2
      }) => ({
        url: `frames/${frameId}/mount`,
        method: 'POST',
        body: {
          tokenId,
          tokenAddress,
          width,
          height,
          signer,
          signature,
          chain,
          signer2,
          signature2
        }
      })
    }),
    deleteUnmountFrame: builder.mutation({
      query: ({ frameId, signer, signature }) => ({
        url: `frames/${frameId}/unmount`,
        method: 'DELETE',
        body: { signer, signature }
      })
    })
  })
})

export const {
  useGetFrameQuery,
  usePostMountFrameQuery,
  useDeleteUnmountFrameMutation
} = Api
