import React from 'react'
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Virtual,
  Mousewheel,
  EffectCoverflow
} from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'

// import Swiper core and required modules
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Virtual,
  Mousewheel,
  EffectCoverflow
])

export default function Carousel (props) {
  const createContent = (content, index) => (
    <SwiperSlide
      key={'content' + index}
      virtualIndex={index}
      onClick={() => {
        props.onItemClick && props.onItemClick(content, index)
      }}
    >
      {/* {props.onCreateContent(content, index)} */}
      {({ isActive, isPrev, isNext, isVisible, isDuplicate }) => {
        isVisible &&
          props.onSlideVisible &&
          setTimeout(() => props.onSlideVisible(index, isVisible), 0)

        return props.onCreateContent(content, index)
      }}
    </SwiperSlide>
  )

  return (
    <Swiper
      {...props}
      {...(props.virtual && {
        slides: props.contents,
        renderSlide: createContent
      })}
      watchSlidesProgress={true}
      watchSlidesVisibility={true}
      {...(props.mousewheelEnabled && {
        mousewheel: {
          enabled: true,
          thresholdDelta: 1,
          sensitivity: 1,
          timeout: 5
        }
      })}
      freeModeMomentumRatio={0.45}
      freeModeSticky={props.freeMode}
      {...(props.coverflow && {
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 30,
          stretch: 110,
          depth: 175,
          modifier: 1,
          slideShadows: true
        }
      })}
    >
      {props.contents.map((content, index) => {
        return createContent(content, index)
      })}
    </Swiper>
  )
}
