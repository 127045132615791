import { useState, useEffect, useCallback } from 'react'
import { useMintManagerContract } from './useContract'

export const useCheckMintStage = (account, immediate = true) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const mintManagerContract = useMintManagerContract()

  const execute = useCallback(async () => {
    if (!mintManagerContract) return

    setIsLoading(true)
    setValue(null)
    setError(null)

    try {
      const result = await mintManagerContract.methods.mintMode().call({ from: account })
      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [mintManagerContract, account])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useCheckMintStage
