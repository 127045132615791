import React from 'react'
import { IconButton, Avatar } from '@mui/material'
import { Link } from 'react-router-dom'

import LogoImage from '../../../assets/images/logos/frames.png'

const Logo = () => {
  return (
    <Link to='/'>
      <IconButton
        sx={{
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent'
          }
        }}
      >
        <Avatar
          alt='Frames - home'
          src={LogoImage}
          variant='square'
          sx={{
            width: 48,
            height: 48,
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent'
            }
          }}
        />
      </IconButton>
    </Link>
  )
}

export default Logo
