import Web3 from 'web3'
import getLibrary from './getLibrary'
import { WEB3_READONLY_RPCS } from '../constants'

const getReadOnlyLibrary = chainId => {
  const chainInfo = WEB3_READONLY_RPCS[chainId]
  if (!chainInfo || !chainInfo.rpc) {
    throw new Error(`No read-only provider for chainId ${chainId}`)
  }

  const provider = new Web3.providers.HttpProvider(chainInfo.rpc)
  return getLibrary(provider)
}

export default getReadOnlyLibrary
