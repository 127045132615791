import React, { useState } from 'react'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import Popover from '@mui/material/Popover'
import { ChromePicker } from 'react-color'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useTheme } from '@mui/material/styles'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import IconButton from '@mui/material/IconButton'

const ID_MAIN_COLOR = '1'
const ID_ACCENT_COLOR = '2'

export default function EditSvgMenu (props) {
  const theme = useTheme()

  const [selectedStyleName, setSelectedStyleName] = useState(
    Object.keys(props.styleOptions).find(
      k => props.styleOptions[k] === props.selectedStyle
    )
  )
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [colorLocal, setColorLocal] = useState('')
  const [displayMainColorPicker, setDisplayMainColorPicker] = useState(false)
  const [displayAccentColorPicker, setDisplayAccentColorPicker] = useState(
    false
  )
  const [displayStylePicker, setDisplayStylePicker] = useState(false)

  const popoverOpen = Boolean(popoverAnchorEl)
  const id = popoverOpen ? 'simple-popover' : undefined

  const handleColourMenuOptionClick = event => {
    setDisplayMainColorPicker(event.currentTarget.id === ID_MAIN_COLOR)
    setDisplayAccentColorPicker(event.currentTarget.id === ID_ACCENT_COLOR)
    setColorLocal(
      event.currentTarget.id === ID_MAIN_COLOR
        ? props.mainColor
        : props.accentColor
    )
    setPopoverAnchorEl(event.currentTarget)
  }
  const handleStyleOptionClick = event => {
    setDisplayStylePicker(true)

    setPopoverAnchorEl(event.currentTarget)
  }

  const handleShuffleClick = event => {
    const keys = Object.keys(props.styleOptions)
    props.onStyleChanged(
      props.styleOptions[keys[Math.floor(Math.random() * keys.length)]]
    )
    props.onMainColorChanged(
      '#' +
        '000000'
          .concat(Math.floor(Math.random() * 16777215).toString(16))
          .slice(-6)
    )

    props.onAccentColorChanged(
      '#' +
        '000000'
          .concat(Math.floor(Math.random() * 16777215).toString(16))
          .slice(-6)
    )

    handleClose()
  }
  const handleClose = () => {
    setDisplayMainColorPicker(false)
    setDisplayAccentColorPicker(false)
    setDisplayStylePicker(false)
    setPopoverAnchorEl(null)
  }

  const handleColorChange = color => {
    setColorLocal(color.hex)
  }

  const handleChangeComplete = (color, event) => {
    if (displayMainColorPicker) {
      props.onMainColorChanged(color.hex)
    } else {
      props.onAccentColorChanged(color.hex)
    }
  }

  return (
    <div {...props}>
      <Button
        id={ID_MAIN_COLOR}
        aria-controls='demo-customized-menu'
        aria-haspopup='true'
        aria-expanded={popoverOpen ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleColourMenuOptionClick}
        startIcon={<FormatColorFillIcon style={{ color: props.mainColor }} />}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(1)
        }}
        color='secondary'
      >
        Color
      </Button>
      <Button
        id={ID_ACCENT_COLOR}
        aria-controls='demo-customized-menu'
        aria-haspopup='true'
        aria-expanded={popoverOpen ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleColourMenuOptionClick}
        startIcon={<FormatColorFillIcon style={{ color: props.accentColor }} />}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(1)
        }}
        color='secondary'
      >
        Accent
      </Button>
      <Button
        id={ID_ACCENT_COLOR}
        aria-controls='demo-customized-menu'
        aria-haspopup='true'
        aria-expanded={popoverOpen ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleStyleOptionClick}
        startIcon={<BurstModeIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        color='secondary'
        style={{
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(1)
        }}
      >
        Style
      </Button>
      <IconButton
        id='shuffle'
        size='small'
        variant='contained'
        disableElevation
        onClick={handleShuffleClick}
        color='secondary'
        style={{
          marginBottom: theme.spacing(1),
          backgroundColor: 'white'
        }}
      >
        <ShuffleIcon />
      </IconButton>

      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {displayMainColorPicker && (
          <ChromePicker
            color={colorLocal}
            onChange={handleColorChange}
            onChangeComplete={handleChangeComplete}
          />
        )}
        {displayAccentColorPicker && (
          <ChromePicker
            color={colorLocal}
            onChange={handleColorChange}
            onChangeComplete={handleChangeComplete}
          />
        )}

        {displayStylePicker && (
          <FormControl component='fieldset' style={{ paddingLeft: '10px' }}>
            <RadioGroup
              aria-label='gender'
              name='controlled-radio-buttons-group'
              value={selectedStyleName}
              onChange={event => {
                setSelectedStyleName(event.target.value)
                props.onStyleChanged(props.styleOptions[event.target.value])
                handleClose()
              }}
            >
              {Object.keys(props.styleOptions).map(function (
                keyName,
                keyIndex
              ) {
                return (
                  <FormControlLabel
                    key={keyIndex}
                    value={keyName}
                    control={<Radio />}
                    label={keyName}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        )}
      </Popover>
    </div>
  )
}
