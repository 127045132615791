import {
  SIGNATURE_HEIGHT,
  SIGNATURE_WIDTH,
  PLAQUE_HEIGHT,
  PLAQUE_WIDTH
} from '../traitsystem/FrameSvgConstants'

const PATH_CAT =
  'M70.3 45.92 64.54 27.37 56.41 32.03 43.59 32.03 34.68 27.37 29.7 45.92 29.7 58.74 36.54 65.79 43.59 72.63 56.41 72.63 63.42 65.75 70.3 58.74z;'
const PATH_WOLF =
  'M69.44 47.28 64.54 24.72 55.99 34.52 44.01 34.52 35.46 24.72 30.56 47.28 29.7 60.97 39.97 61.74 42.77 75.28 57.23 75.28 60.03 61.74 70.3 60.97z;'
const PATH_T_UPSIDE_DOWN =
  'M70.3 57.48 56.41 57.48 56.41 29.7 43.59 29.7 43.59 57.48 29.7 57.48 29.7 70.3 37.87 70.3 43.59 70.3 56.41 70.3 62.02 70.3 70.3 70.3z;'
const PATH_T =
  'M70.3 29.7 62.8 29.7 56.4 29.7 43.6 29.7 38.6 29.7 29.7 29.7 29.7 42.5 43.6 42.5 43.6 70.3 56.4 70.3 56.4 42.5 70.3 42.5z;'
const PATH_Z =
  'M70.3 57.48 56.41 57.48 56.41 29.7 46.97 29.7 43.59 29.7 29.7 29.7 29.7 42.52 43.59 42.52 43.59 70.3 53.03 70.3 56.41 70.3 70.3 70.3z;'
const PATH_S =
  'M70.3 29.7 61.74 29.7 56.41 29.7 43.59 29.7 43.59 57.48 29.7 57.48 29.7 70.3 40.43 70.3 43.59 70.3 56.41 70.3 56.41 42.52 70.3 42.52z;'
const PATH_GHOST =
  'M70.3 43.59 65.32 33.43 56.41 29.7 43.59 29.7 35.77 33.28 29.7 43.59 29.7 70.3 40.14 66.96 43.59 70.3 55.7 70.3 61.15 66.57 70.3 70.3z;'
const PATH_CUT_DIAMOND =
  'M60.83 29.67 51.72 29.67 50 29.67 48.28 29.67 39.17 29.67 35.48 29.67 25.27 39.41 25.27 46.97 50 74.21 74.73 46.97 74.73 39.41 64.52 29.67z;'
const PATH_PAC =
  'M44.24 48.52 68.63 40.17 58.04 29.7 45.22 29.7 38.1 36.47 31.33 43.59 31.33 56.41 38.18 63.46 45.22 70.3 58.04 70.3 68.67 59.8 44.24 51.32z;'
const PATH_GEMSTONE =
  'M57.49 35.61 50.01 28.12 42.52 35.61 35.61 42.52 35.5 50 35.61 57.48 42.52 64.39 50.01 71.87 57.49 64.39 64.4 57.48 64.5 50 64.4 42.52z;'
const PATH_STAR =
  'M72.09 37.25 56.38 38.96 50 24.5 43.62 38.96 27.91 37.25 37.25 50 27.91 62.75 43.62 61.04 50 75.5 56.38 61.04 72.09 62.75 62.75 50z;'
const PATH_X =
  'M59.82 31.11 50 40.93 40.18 31.11 31.11 40.18 40.93 50 31.11 59.82 40.18 68.89 50 59.07 59.82 68.89 68.89 59.82 59.07 50 68.89 40.18z;'
const PATH_DIAMOND =
  'M57.48 35.61 50 28.13 42.52 35.61 35.61 42.52 28.13 50 35.61 57.48 42.52 64.39 50 71.87 57.48 64.39 64.39 57.48 71.87 50 64.39 42.52z;'
const PATH_HEART =
  'M60.83 27.73 51.72 37.76 50 37.76 48.28 37.76 39.17 27.73 35.48 27.73 25.27 37.47 25.27 45.03 50 72.27 74.73 45.03 74.73 37.47 64.52 27.73z;'
const PATH_OCTAGON =
  'M70.3 43.59 63.42 36.58 56.41 29.7 43.59 29.7 36.47 36.47 29.7 43.59 29.7 56.41 36.54 63.46 43.59 70.3 56.41 70.3 63.42 63.42 70.3 56.41z;'
const PATH_PLUS =
  'M70.3 43.59 56.41 43.59 56.41 29.7 43.59 29.7 43.59 43.59 29.7 43.59 29.7 56.41 43.59 56.41 43.59 70.3 56.41 70.3 56.41 56.41 70.3 56.41z;'
const PATHS = [
  PATH_CAT,
  PATH_WOLF,
  PATH_T_UPSIDE_DOWN,
  PATH_T,
  PATH_Z,
  PATH_S,
  PATH_GHOST,
  PATH_CUT_DIAMOND,
  PATH_PAC,
  PATH_GEMSTONE,
  PATH_STAR,
  PATH_X,
  PATH_DIAMOND,
  PATH_HEART,
  PATH_PLUS,
  PATH_OCTAGON
]

const OPACITY_VALUES = [
  '0.0625;',
  '0.125;',
  '0.1875;',
  '0.25;',
  '0.3125;',
  '0.375;',
  '0.4375;',
  '0.5;',
  '0.5625;',
  '0.625;',
  '0.6875;',
  '0.75;',
  '0.8125;',
  '0.875;',
  '0.9375;',
  '1.0;'
]

const keyTimes = `0;
0.1;
0.125;
0.150;
0.175;
0.2;
0.225;
0.25;
0.275;
0.300;
0.325;
0.35;
0.375;
0.4;
0.425;
0.45;
0.475;
0.5;
0.525;
0.55;
0.575;
0.6;
0.625;
0.65;
0.675;
0.700;
0.725;
0.75;
0.775;
0.8;
0.825;
0.85;
0.875;
0.9;
1`

const PLAY_BUTTON =
  'M60.88 46.58 52.25 41.6 45.73 37.84 39.67 34.33 33.2 30.6 33.2 43.82 33.2 56.42 33.2 69.4 41.41 64.66 51.43 58.87 58.54 54.77 66.8 50z;'
const STOP_BUTTON =
  'M70.3 43.59 70.3 29.7 56.41 29.7 43.59 29.7 29.7 29.7 29.7 43.59 29.7 56.41 29.7 70.3 43.59 70.3 56.41 70.3 70.3 70.3 70.3 56.41z;'

const getSvgSignature = (
  traitHash = '0xee9cecc057d9a9ce918cd55adef7142ffc5cad27a0a95061825673e977790b5d'
) => {
  traitHash = traitHash.slice(2) // remove 0x
  const shapeIndexArray = []
  const colourIndexArray = []
  for (let i = 0; i < 32; i++) {
    // first 32 bits are shape index
    shapeIndexArray.push(parseInt('0x'.concat(traitHash.slice(i, i + 1))))
    // next 32 bits are colour index
    colourIndexArray.push(
      parseInt('0x'.concat(traitHash.slice(i + 32, i + 32 + 1)))
    )
  }

  let shapes = ''

  for (let i = 0; i < shapeIndexArray.length; i++) {
    shapes += PATHS[shapeIndexArray[i]] + '\n'
  }

  let opacities = ''
  for (let i = 0; i < colourIndexArray.length; i++) {
    opacities += OPACITY_VALUES[colourIndexArray[i]] + '\n'
  }

  return `
  <g transform="translate(${PLAQUE_WIDTH / 2 -
    SIGNATURE_WIDTH / 2},${PLAQUE_HEIGHT / 2 - SIGNATURE_HEIGHT / 2})">
<path class="prefix_accent_colour" stroke="#000000" stroke-width="3">
<animate 
attributeName="d" 
dur="20s" 
repeatCount="indefinite"
keyTimes="${keyTimes}"
calcMode="spline" 
keySplines="0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;"
values="${STOP_BUTTON}
${PLAY_BUTTON}
${shapes}
${STOP_BUTTON}"/>
</path>
<path fill="transparent" stroke="#ffffff" stroke-width="2">
<animate 
attributeName="d" 
dur="20s" 
repeatCount="indefinite"
keyTimes="${keyTimes}"
calcMode="spline" 
keySplines="0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;"
          values="${STOP_BUTTON}
            ${PLAY_BUTTON}
            ${shapes}
            ${STOP_BUTTON}"/>
</path>
<path fill="#000000">
<animate 
attributeName="d" 
dur="20s" 
repeatCount="indefinite"
keyTimes="${keyTimes}"
calcMode="spline" 
keySplines="0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;"
            values="${STOP_BUTTON}
            ${PLAY_BUTTON}
            ${shapes}
            ${STOP_BUTTON}"/>
<animate 
attributeName="opacity"
dur="20s" 
repeatCount="indefinite"
keyTimes="${keyTimes}"
calcMode="spline" 
keySplines="0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;
            0.1 0.8 0.2 1;"
values="${OPACITY_VALUES[0]}
${OPACITY_VALUES[0]}
${opacities}
${OPACITY_VALUES[0]}"/>
</path>
</g>
  `
}

export default getSvgSignature
