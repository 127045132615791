import { constructSvgString } from './ConstructSvgStringUsecase'
import { getAndProcessTokenMetadata } from './GetAndProcessTokenMetadataUseCase'

export const constructFrameNftWithBaseSvg = async (
  web3,
  contractAddress,
  tokenId,
  chainId = 1,
  baseSvgString,
  dimensions
) => {
  let metadata

  try {
    metadata = await getAndProcessTokenMetadata(
      web3,
      contractAddress,
      tokenId,
      chainId,
      dimensions ? false : true,
      true
    )
  } catch (e) {
    console.error(
      'Error fetching metadata for : ' + contractAddress + ' ' + tokenId,
      e
    )
  }

  if (!metadata) {
    throw new Error(
      'Error fetching metadata for : ' + contractAddress + ' ' + tokenId
    )
  }

  const newSvg = constructSvgString(
    metadata.display_url,
    dimensions ? dimensions.height : metadata.height,
    dimensions ? dimensions.width : metadata.width,
    metadata.mimeType,
    baseSvgString
  )

  return {
    svg: newSvg,
    width: dimensions ? dimensions.width : metadata.width,
    height: dimensions ? dimensions.height : metadata.height,
    failedDimensionDetection: !dimensions
      ? metadata.failedDimensionDetection
      : false
  }
}
