import toast from 'react-hot-toast'

const defaultStyle = {
  maxWidth: '500px',
  wordBreak: 'break-word',
  background: '#333',
  color: '#fff'
}

export const success = (message, { duration = 2000 } = {}) => {
  return toast.success(message, { style: defaultStyle, duration })
}

export const error = (message, { duration = 2000 } = {}) => {
  return toast.error(message, { style: defaultStyle, duration })
}

export const warning = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: '⚠️', style: defaultStyle, duration })
}

export const info = (message, { duration = 4000 } = {}) => {
  return toast(message, { icon: 'ℹ️', style: defaultStyle, duration })
}

export const loading = message => {
  return toast.loading(message, { style: defaultStyle })
}

export const dismiss = toastId => {
  return toast.dismiss(toastId)
}

export const promise = (promiseFunction, messages) => {
  return toast.promise(
    promiseFunction,
    {
      loading: messages.loading,
      success: messages.success,
      error: messages.error
    },
    { style: defaultStyle }
  )
}

export default { success, error, warning, info, loading, dismiss, promise }
