import { isApprovedForAll } from '../datasource/Erc1155DataSource'

export const getIsErc1155TransferApprovedFromAddress = async (
  web3,
  tokenContractAddress,
  account,
  spenderAddress
) => {
  return isApprovedForAll(web3, tokenContractAddress, account, spenderAddress)
}
