import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'

const SectionTitle = ({ title }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Title variant={isMobile ? 'h4' : 'h3'} component='p' align='center'>
      {title}
    </Title>
  )
}

const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(6)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  width: 100%;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-shadow: 2px 2px 5px black;
`

export default SectionTitle
