import React, { useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import ConnectWalletModal from '../components/common/wallet/modal'
import MintingForm from '../components/common/MintingForm'
import Container from '../components/common/PageContainer'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import { switchToEthereum } from '../usecase/SwitchToEthereumUsecase'
const MintPage = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <Container>
      <Content open={() => setOpenModal(true)} />
      <ConnectWalletModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Container>
  )
}

const Content = ({ open }) => {
  const { account, error } = useWeb3React()
  const [amount, setAmount] = useState(1)

  if (error)
    return (
      <Error
        onClick={() => {
          open()
          switchToEthereum(account)
        }}
        text={
          error instanceof UnsupportedChainIdError
            ? 'Unsupported Network'
            : 'Error'
        }
      />
    )

  if (account) {
    return (
      <MintingForm
        openAddressModal={open}
        amount={amount}
        setAmount={setAmount}
      />
    )
  }

  return <ConnectWalletButton onClick={open} text='Connect Your Wallet' />
}

export default MintPage
