import React from 'react'
import styled from 'styled-components'
import NavBar from './Navbar'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <NavBar />
      <Content>
        {children}
      </Content>
      <Footer />
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

export default Layout
