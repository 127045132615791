import { getAndProcessTokenMetadata } from './GetAndProcessTokenMetadataUseCase'
import { getContractDetails } from './GetContractDetailsUseCase'
import { checkOwnsToken } from './CheckOwnsTokenUseCase'

export const checkAndGetTokenMetadata = async (
  web3,
  account,
  contractAddress,
  tokenId,
  chainId
) => {
  const contractInfo = await getContractDetails(web3, contractAddress)
  console.log(contractInfo)
  let nftData = null
  let nftMetadata = null
  const ownsToken = await checkOwnsToken(
    web3,
    account,
    contractAddress,
    tokenId,
    contractInfo.type
  )
  console.log('ownsToken?', ownsToken)
  if (ownsToken) {
    nftMetadata = await getAndProcessTokenMetadata(
      web3,
      contractAddress,
      tokenId,
      chainId
    )

    nftData = {
      contract: contractInfo,
      tokenID: tokenId
    }
  } else {
    throw new Error(`You don't own any of these tokens`)
  }

  return {
    nftMetadata,
    nftData
  }
}
