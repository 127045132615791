import React from 'react'
import { Button, Avatar } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import useMediaQuery from '@mui/material/useMediaQuery'

import MobileLogo from '../../../assets/images/logos/murall-mobile.svg'
import DesktopLogo from '../../../assets/images/logos/murall.svg'

export default function MurAllLink () {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Button
      sx={{
        '&.MuiButtonBase-root:hover': {
          bgcolor: 'transparent'
        }
      }}
      startIcon={
        <Avatar
          alt='Frames - home'
          src={isMobile ? MobileLogo : DesktopLogo}
          variant='square'
          sx={{
            width: (theme) => isMobile ? theme.spacing(4) : theme.spacing(11),
            height: (theme) => isMobile ? theme.spacing(4) : theme.spacing(3)
          }}
        />
      }
      endIcon={<LaunchIcon />}
      component='a'
      target='_blank'
      href='https://murall.art'
    />
  )
}
