import React from 'react'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useWeb3React } from '@web3-react/core'
import { shortenAddress } from '../../../lib/web3/utils'
import Typography from '@mui/material/Typography'
import WalletIcon from './Icon'

const AccountAddress = ({ textSize = 'h5' }) => {
  const { account } = useWeb3React()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Container>
      <WalletIcon />
      {account && <Typography variant={textSize}>{shortenAddress(account, isMobile ? 3 : 4)}</Typography>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

export default AccountAddress
