import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import clsx from 'clsx'

const FormBackground = ({ children }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Card
      className={clsx({ framesSlant: !isMobile })}
      elevation={0}
      sx={{
        minWidth: 275,
        padding: isMobile ? '24px' : '48px'
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>{children}</CardContent>
    </Card>
  )
}

export default FormBackground
