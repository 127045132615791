import { useMemo } from 'react'
import { useActiveWeb3React } from './web3'
import MurAllFrameAbi from '../assets/contracts/MurAllFrame.json'
import MintManagerAbi from '../assets/contracts/MintManager.json'

// returns null on errors
export const useContract = buildFile => {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!buildFile || !library || !chainId) return null
    const ABI = buildFile.abi
    const networkData = buildFile.networks[chainId]
    const address = networkData && networkData.address
    if (!ABI || !address) return null
    try {
      return new library.eth.Contract(ABI, address)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [buildFile, library, chainId])
}

export const useMurAllFrameContract = () => {
  return useContract(MurAllFrameAbi)
}

export const useMintManagerContract = () => {
  return useContract(MintManagerAbi)
}
