import { SupportedChain } from '../constants'

// TODO: change these URLs as they are shared with the MurAll site
export const NetworkProviderUrls = {
  [SupportedChain.Ethereum]:
    'wss://eth-mainnet.alchemyapi.io/v2/qJd-V4xaqrcxqg32aZ_Hv8lSyo0jLy8z',
  [SupportedChain.Ropsten]:
    'wss://eth-ropsten.alchemyapi.io/v2/gnUvQSfMX8aP4LheyAn7ny0CVOwCEP6u',
  [SupportedChain.Rinkeby]:
    'wss://eth-rinkeby.alchemyapi.io/v2/JGz2bmtzVTud8cbdN7TR10elQBkwK7W8',
  [SupportedChain.Goerli]:
    'wss://eth-goerli.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn'
}

export const NetworkProviderHttpUrls = {
  [SupportedChain.Ethereum]:
    'https://eth-mainnet.alchemyapi.io/v2/qJd-V4xaqrcxqg32aZ_Hv8lSyo0jLy8z',
  [SupportedChain.Ropsten]:
    'https://eth-ropsten.alchemyapi.io/v2/gnUvQSfMX8aP4LheyAn7ny0CVOwCEP6u',
  [SupportedChain.Rinkeby]:
    'https://eth-rinkeby.alchemyapi.io/v2/JGz2bmtzVTud8cbdN7TR10elQBkwK7W8',
  [SupportedChain.Goerli]:
    'https://eth-goerli.alchemyapi.io/v2/8Vpu96KpWOtMuVVU1VpiTaIu6Nu3LtRn'
}
