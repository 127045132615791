import { getIsContractErc1155 } from './GetIsContractErc1155Usecase'
import { getIsContractErc721 } from './GetIsContractErc721Usecase'

export const getContractDetails = async (web3, contractAddress) => {
  let name = ''
  let symbol = ''
  let type

  let isErc1155 = false
  try {
    isErc1155 = await getIsContractErc1155(web3, contractAddress)
  } catch (e) {
    console.error('Error checking if contract is ERC1155', e)
  }
  if (!isErc1155) {
    const isErc721 = await getIsContractErc721(web3, contractAddress)

    if (!isErc721) {
      throw new Error('Unsupported contract type - must be ERC115 or ERC721')
    } else {
      type = 'ERC721'
    }
  } else {
    type = 'ERC1155'
  }

  return {
    id: contractAddress,
    name: name,
    symbol: symbol,
    type: type
  }
}
