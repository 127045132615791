import { gql } from 'graphql-request'

export const GET_NFTS = gql`
  query GetNfts($skip: Int, $first: Int, $owner: String) {
    ownerships(skip: $skip, first: $first, where: { owner: $owner }) {
      quantity
      nft {
        tokenID
        contract {
          id
          name
          symbol
          type
        }
      }
    }
  }
`
