import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import Fade from 'react-reveal/Fade'
const CustomAccordion = ({ items = [] }) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false)

  const createAccordionSection = ({ id, title, content }) => {
    return (
      <Fade top cascade>
        <Accordion
          disableGutters
          elevation={0}
          key={id}
          expanded={expanded === id}
          onChange={handleChange(id)}
        >
          <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon fontSize='inherit' />}
          >
            <Title variant='h6' component='h6'>
              {title}
            </Title>
          </AccordionSummary>
          <MuiAccordionDetails>
            <Content>{content}</Content>
          </MuiAccordionDetails>
        </Accordion>
      </Fade>
    )
  }

  return items.map((item, idx) =>
    createAccordionSection({
      title: item.title,
      content: item.content,
      id: `item-${idx}`
    })
  )
}

const Accordion = styled(MuiAccordion)`
  flex: 1;
  width: 100%;
  transition: all 300ms;
  background-color: ${({ theme, expanded }) =>
    expanded ? theme.palette.primary.main + '50' : 'transparent'};
  &:before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`

const Title = styled(Typography)`
  font-family: Roboto;
  font-weight: 300;
  padding-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-shadow: 2px 2px 5px black;
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-family: Roboto;
  font-weight: 300;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  text-shadow: 2px 2px 5px black;
`

export default CustomAccordion
