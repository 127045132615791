import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-top: 75px;
  height: calc(100vh - 75px - 91px);
`

export default Container
