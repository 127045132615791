import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(({ theme, right }) => ({
  '& .MuiBadge-badge': {
    right: right || -14,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  },
}))

export default StyledBadge
