import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const NavLink = ({ title, to }) => {
  return (
    <StyledLink to={to}>{title}</StyledLink>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  min-width: ${({ theme }) => theme.spacing(6)};
  text-align: center;
  color: inherit;
  font-weight: 300;
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)};
  font-size: 0.875rem;
  line-height: 1.75;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`

export default NavLink
