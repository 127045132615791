import { isTokenTransferApproved } from '../datasource/Erc721DataSource'

export const getIsErc721TransferApprovedFromAddress = async (
  web3,
  erc721TokenContractAddress,
  account,
  spenderAddress
) => {
  return isTokenTransferApproved(
    web3,
    erc721TokenContractAddress,
    account,
    spenderAddress
  )
}
