import React from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'

import WidthLimiter from '../components/common/WidthLimiter'
import SectionTitle from '../components/common/SectionTitle'
import PeerThroughMediaLogo from '../assets/images/logos/peer-through-media.png'
import NFTEveningLogo from '../assets/images/logos/nftevening.png'
import NFTCalendarLogo from '../assets/images/logos/nftcalendar.png'

import { openInNewTab } from '../lib/utils'

const FeaturedSection = () => {
  return (
    <Container>
      <SectionTitle title='Find Us On' />
      <Grid container textAlign='center' mb={8}>
        <Grid item xs={12} md={4}>
          <Logo src={NFTEveningLogo} alt='nftevening' onClick={() => openInNewTab('https://nftevening.com/event/frames-drop/')} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Logo src={NFTCalendarLogo} alt='nftcalendar' width={150} onClick={() => openInNewTab('https://nftcalendar.io/event/frames-by-murall/')} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Logo src={PeerThroughMediaLogo} alt='peerthroughmedia' width={240} onClick={() => openInNewTab('https://peerthroughmedia.com/nft-drops-calendar')} />
        </Grid>
      </Grid>
    </Container>
  )
}

const Container = styled(WidthLimiter)`
  flex-direction: column;
`

const Logo = styled.img`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: ${({ width }) => width ? width : 290}px;
  object-fit: contain;
  height: 100px;
`

export default FeaturedSection
