const L1_NETWORK_ID_MAINNET = 1

export const switchToEthereum = account => {
  const params = { chainId: '0x' + L1_NETWORK_ID_MAINNET.toString(16) }
  if (window.ethereum) {
    window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [params, account]
    })
  }
}
