const getTileSvg = () => {
  return `<svg>
  <g id="Layer_7">
    <path class="prefix_main_colour" d="M.03 0h249.94v125H.03z"/>
    <defs>
      <path id="SVGID_00000083807057501520220580000013723760218129798551_" d="M124.97 0h125v125h-125z"/>
    </defs>
    <clipPath id="SVGID_00000075157462821589426330000007255342527886713758_">
      <use xlink:href="#SVGID_00000083807057501520220580000013723760218129798551_" style="overflow:visible"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000075157462821589426330000007255342527886713758_)">
      <circle transform="rotate(-45 124.97156 18.66818)" cx="124.97" cy="18.67" r="5.33"/>
      <circle cx="124.97" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 124.94289 61.32404)" cx="124.97" cy="61.33" r="8.67"/>
      <circle cx="124.97" cy="83" r="10"/>
      <circle cx="249.97" cy="18.67" r="5.33"/>
      <circle cx="249.97" cy="39.67" r="7"/>
      <circle cx="249.97" cy="61.33" r="8.67"/>
      <circle cx="249.97" cy="83" r="10"/>
      <path d="M257.01 113.4c2.66-2.09 4.38-5.33 4.38-8.98 0-6.31-5.11-11.42-11.42-11.42-4.64 0-8.63 2.78-10.42 6.76-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76c-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76c-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76c-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76c-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76c-1.78-3.98-5.77-6.76-10.42-6.76-6.31 0-11.42 5.11-11.42 11.42 0 3.65 1.72 6.89 4.38 8.98-3.63 2.32-6.05 6.39-6.05 11.02 0 7.23 5.86 13.08 13.08 13.08 4.26 0 8.03-2.04 10.42-5.19 2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19 7.23 0 13.08-5.86 13.08-13.08.01-4.64-2.4-8.7-6.03-11.02zm-17.46-4.33c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.61-2.61 3.38-4.32zm-20.84 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.61-2.61 3.38-4.32z"/>
      <circle transform="rotate(-13.286 145.7742 18.667)" cx="145.8" cy="18.67" r="5.33"/>
      <circle cx="145.8" cy="39.67" r="7"/>
      <circle transform="rotate(-45 145.80456 61.33525)" cx="145.8" cy="61.33" r="8.67"/>
      <circle cx="145.8" cy="83" r="10"/>
      <circle cx="166.64" cy="18.67" r="5.33"/>
      <circle cx="166.64" cy="39.67" r="7"/>
      <circle cx="166.64" cy="61.33" r="8.67"/>
      <circle cx="166.64" cy="83" r="10"/>
      <circle cx="187.47" cy="18.67" r="5.33"/>
      <circle cx="187.47" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 187.43004 61.326)" cx="187.47" cy="61.33" r="8.67"/>
      <circle cx="187.47" cy="83" r="10"/>
      <circle transform="rotate(-13.286 229.09074 18.66922)" cx="229.14" cy="18.67" r="5.33"/>
      <circle cx="229.14" cy="39.67" r="7"/>
      <circle transform="rotate(-45 229.1383 61.33632)" cx="229.14" cy="61.33" r="8.67"/>
      <circle cx="229.14" cy="83" r="10"/>
      <circle transform="rotate(-45 208.30535 18.66912)" cx="208.3" cy="18.67" r="5.33"/>
      <circle cx="208.3" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 208.25857 61.32617)" cx="208.3" cy="61.33" r="8.67"/>
      <circle cx="208.3" cy="83" r="10"/>
    </g>
    <defs>
      <path id="SVGID_00000168819072141954484070000005834051551653075377_" d="M.03 0h125v125H.03z"/>
    </defs>
    <clipPath id="SVGID_00000153671633636931776960000005010360518368213893_">
      <use xlink:href="#SVGID_00000168819072141954484070000005834051551653075377_" style="overflow:visible"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000153671633636931776960000005010360518368213893_)">
      <circle transform="rotate(-45 .02857 18.66676)" cx=".03" cy="18.67" r="5.33"/>
      <circle cx=".03" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 .02687 61.32036)" cx=".03" cy="61.33" r="8.67"/>
      <circle cx=".03" cy="83" r="10"/>
      <circle cx="125.03" cy="18.67" r="5.33"/>
      <circle cx="125.03" cy="39.67" r="7"/>
      <circle cx="125.03" cy="61.33" r="8.67"/>
      <circle cx="125.03" cy="83" r="10"/>
      <path d="M132.06 113.4c2.66-2.09 4.38-5.33 4.38-8.98 0-6.31-5.11-11.42-11.42-11.42-4.64 0-8.63 2.78-10.42 6.76-1.78-3.98-5.77-6.76-10.42-6.76s-8.63 2.78-10.42 6.76C92 95.78 88.01 93 83.36 93s-8.63 2.78-10.42 6.76C71.16 95.78 67.17 93 62.53 93s-8.63 2.78-10.42 6.76C50.33 95.78 46.34 93 41.7 93s-8.63 2.78-10.42 6.76C29.5 95.78 25.51 93 20.86 93s-8.63 2.78-10.42 6.76C8.66 95.78 4.67 93 .03 93c-6.31 0-11.42 5.11-11.42 11.42 0 3.65 1.72 6.89 4.38 8.98-3.63 2.32-6.05 6.39-6.05 11.02 0 7.23 5.86 13.08 13.08 13.08 4.26 0 8.03-2.04 10.42-5.19 2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19s8.03-2.04 10.42-5.19c2.39 3.15 6.16 5.19 10.42 5.19 7.23 0 13.08-5.86 13.08-13.08-.03-4.64-2.44-8.7-6.08-11.02zm-17.45-4.33c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.44-1.13 2.61-2.61 3.38-4.32zm-20.84 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.45-1.13 2.62-2.61 3.38-4.32zm-20.83 0c.76 1.7 1.93 3.18 3.38 4.32-1.31.84-2.45 1.9-3.38 3.13-.93-1.23-2.08-2.29-3.38-3.13 1.44-1.13 2.61-2.61 3.38-4.32z"/>
      <circle transform="rotate(-13.286 20.85728 18.66365)" cx="20.86" cy="18.67" r="5.33"/>
      <circle cx="20.86" cy="39.67" r="7"/>
      <circle transform="rotate(-45 20.86157 61.33384)" cx="20.86" cy="61.33" r="8.67"/>
      <circle cx="20.86" cy="83" r="10"/>
      <circle cx="41.7" cy="18.67" r="5.33"/>
      <circle cx="41.7" cy="39.67" r="7"/>
      <circle cx="41.7" cy="61.33" r="8.67"/>
      <circle cx="41.7" cy="83" r="10"/>
      <circle cx="62.53" cy="18.67" r="5.33"/>
      <circle cx="62.53" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 62.51402 61.32233)" cx="62.53" cy="61.33" r="8.67"/>
      <circle cx="62.53" cy="83" r="10"/>
      <circle transform="rotate(-13.286 104.17381 18.66588)" cx="104.2" cy="18.67" r="5.33"/>
      <circle cx="104.2" cy="39.67" r="7"/>
      <circle transform="rotate(-45 104.1952 61.33485)" cx="104.2" cy="61.33" r="8.67"/>
      <circle cx="104.2" cy="83" r="10"/>
      <circle transform="rotate(-45 83.36236 18.6677)" cx="83.36" cy="18.67" r="5.33"/>
      <circle cx="83.36" cy="39.67" r="7"/>
      <circle transform="rotate(-13.286 83.34293 61.32297)" cx="83.36" cy="61.33" r="8.67"/>
      <circle cx="83.36" cy="83" r="10"/>
    </g>
  </g>
</svg>`
}

export default getTileSvg