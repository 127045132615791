import { getBalanceForAddress } from '../datasource/Erc1155DataSource'
import { ownerOf } from '../datasource/Erc721DataSource'

export const checkOwnsToken = async (
  web3,
  account,
  contractAddress,
  tokenId,
  contractType
) => {
  let ownsToken = false

  if (contractType === 'ERC1155') {
    const balance = await getBalanceForAddress(
      web3,
      contractAddress,
      account,
      tokenId
    )
    ownsToken = balance > 0
  } else {
    const tokenOwnerAddress = await ownerOf(web3, contractAddress, tokenId)
    ownsToken = tokenOwnerAddress.toUpperCase() === account.toUpperCase()
  }

  return ownsToken
}
