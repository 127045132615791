import React from 'react'
import Box from '@mui/material/Box'

export default function DefaultContainer (props) {
  return (
    <Box
      {...props}
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {}),
        display: 'flex',
        margin: 'auto',
        padding: 0,
        position: 'relative'
      }}
    >
      {props.children}
    </Box>
  )
}
