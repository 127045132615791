import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import Carousel from '../Carousel'
import styled from 'styled-components'
import DefaultContainer from '../../DefaultContainer'
import MountedContentPreview from './MountedContentPreview'
import NftContainer from './NftContainer'
import NftLoadingView from './NftLoadingView'
import { signMessage } from '../../../usecase/SignMessageUsecase'
import { signSolanaMessage } from '../../../usecase/SignSolanaMessageUsecase'
import { CHAIN_INFO_BY_NAME, WEB3_READONLY_RPCS } from '../../../lib/constants'
import notification from '../../../lib/notification'
import { useWeb3React } from '@web3-react/core'
import { useBindContentsToFrame } from '../../../hooks/useBindContentsToFrame'
import { useCheckContentsBoundToFrame } from '../../../hooks/useCheckContentsBoundToFrame'
import {
  usePostMountFrameQuery,
  useDeleteUnmountFrameMutation
} from '../../../state/api'
import TokenInput from './TokenInput'
import SolanaTokenInput from './SolanaTokenInput'
import PreviewDetails from './PreviewDetails'
import Paper from '@mui/material/Paper'
import ChainSelector from './ChainSelector'

const LOADING_ID = 'loading'

const LeftContainer = styled.div`
  display: inline-flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin: 0;
  height: 100%;
`
const RightContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  margin: 0;
  height: 100%;
`

export default function MountPageContent (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { account, library } = useWeb3React()
  const theme = useTheme()
  const [loadingPlaceholder, setLoadingPlaceholder] = useState(
    Array(props.loadingViews).fill(LOADING_ID)
  )
  const [isMounting, setIsMounting] = useState(false)
  const [isUnmounting, setIsUnmounting] = useState(false)
  const [notificationId, setNotificationId] = useState(null)
  const [selectedFrame, setSelectedFrame] = useState(null)
  const [refreshFrame, setRefreshFrame] = useState(null)
  const [selectedFrameMetadata, setSelectedFrameMetadata] = useState(null)
  const [selectedNft, setSelectedNft] = useState(null)
  const [selectedNftMetadata, setSelectedNftMetadata] = useState(null)
  const [frameDimensions, setFrameDimensions] = useState(null)
  const [signedMessage, setSignedMessage] = useState(null)
  const [crossChainSignedMessage, setCrossChainSignedMessage] = useState(null)
  const [crossChainAccount, setCrossChainAccount] = useState(null)
  const [chainId, setChainId] = useState(1)

  const {
    execute: executeBind,
    isLoading: isBinding,
    value,
    error
  } = useBindContentsToFrame(
    account,
    selectedFrame ? selectedFrame.tokenID : null,
    selectedNft ? selectedNft.contract.id : null,
    selectedNft ? selectedNft.tokenID : null,
    1,
    false
  )

  const {
    execute: executeCheckContentsBoundToFrame,
    isLoading: isCheckingBoundToFrame,
    value: bindStatus,
    error: checkError
  } = useCheckContentsBoundToFrame(
    selectedFrame ? selectedFrame.tokenID : null,
    false
  )

  const {
    data,
    error: mountError,
    isLoading: isMountingContents
  } = usePostMountFrameQuery(
    {
      frameId: selectedFrame ? selectedFrame.tokenID : null,
      tokenId: selectedNft ? selectedNft.tokenID.toString() : null,
      tokenAddress: selectedNft ? selectedNft.contract.id : null,
      width: frameDimensions ? parseInt(frameDimensions.width) : 0,
      height: frameDimensions ? parseInt(frameDimensions.height) : 0,
      chain: parseInt(chainId),
      signer: account,
      signature: signedMessage,
      signer2: crossChainAccount,
      signature2: crossChainSignedMessage
    },

    { refetchOnMountOrArgChange: true, skip: !account || !signedMessage }
  )

  const [
    deleteUnmountFrame,
    { data: unmountData, isLoading: isUnmountingContents }
  ] = useDeleteUnmountFrameMutation()

  useEffect(() => {
    async function onFrameChanged () {
      executeCheckContentsBoundToFrame()
    }

    onFrameChanged()
  }, [selectedFrame])
  useEffect(() => {
    async function onData () {
      if (!isMounting && !isMountingContents) {
        setSignedMessage(null)

        setRefreshFrame(selectedFrame)
        notification.dismiss(notificationId)
        if (mountError) {
          console.error('Error mounting content to frame', mountError)
          notification.error(
            'Error mounting content to frame: ' + mountError.message
          )
        } else if (data) {
          notification.success(
            'Successfully mounted content to frame - refresh to see changes'
          )
        }
      }
    }

    onData()
  }, [isMounting, isMountingContents])

  useEffect(() => {
    async function onSwitchAccount () {
      resetAll()
    }

    onSwitchAccount()
  }, [account])

  const resetAll = () => {
    setSignedMessage(null)
    setSelectedFrame(null)
    setSelectedFrameMetadata(null)
    setSelectedNft(null)
    setFrameDimensions(null)
    setSelectedNftMetadata(null)
    setCrossChainSignedMessage(null)
    setCrossChainAccount(null)
  }

  const carouselBreakpoints = {
    '@0.25': {
      slidesPerView: 1.25,
      spaceBetween: 25
    },
    '@0.5': {
      slidesPerView: 1.55,
      spaceBetween: 25
    },
    '@0.75': {
      slidesPerView: 1.75,
      spaceBetween: 70
    },
    '@1.00': {
      slidesPerView: 2.25,
      spaceBetween: 70
    },
    '@1.50': {
      slidesPerView: 2.65,
      spaceBetween: 70
    },
    '@2.00': {
      slidesPerView: 3.65,
      spaceBetween: 70
    }
  }

  return (
    <Paper
      elevation={0}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        margin: '0',
        width: '100%',
        height: '100%'
      }}
    >
      <LeftContainer>
        <ChainSelector
          sx={{
            width: '100%',
            ...(chainId !== 1 && {
              // marginTop: '0px'
              marginBottom: '0px'
            })
          }}
          onChainSelected={chainInfo => {
            setSelectedNft(null)
            setFrameDimensions(null)
            setSelectedNftMetadata(null)
            setCrossChainSignedMessage(null)
            setCrossChainAccount(null)
            setChainId(parseInt(chainInfo.id))
          }}
        />
        {props.errorLoadingNfts || chainId !== 1 ? (
          <Carousel
            style={{
              flex: 'none',
              width: '100%'
            }}
            centeredSlides={true}
            contents={[props.errorLoadingNfts]}
            onCreateContent={() => {
              return (
                <Typography
                  variant={isMobile ? 'h4' : 'h3'}
                  component='div'
                  align='center'
                  gutterBottom
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 300,
                    textShadow: '2px 2px 5px black',
                    margin: 'auto 0'
                  }}
                >
                  {props.errorLoadingNfts}
                </Typography>
              )
            }}
            breakpoints={carouselBreakpoints}
          />
        ) : (
          <Carousel
            style={{
              flex: 'none',
              width: '100%'
            }}
            coverflow
            mousewheelEnabled={true}
            freeMode={true}
            centeredSlides={true}
            contents={
              props.canFetchMore
                ? props.nfts.concat(loadingPlaceholder)
                : props.nfts
            }
            onCreateContent={nft => {
              return nft !== LOADING_ID ? (
                <NftContainer
                  nft={nft}
                  selected={nft === selectedNft}
                  onItemClick={(content, metadata) => {
                    if (
                      selectedFrame &&
                      content.tokenID.toString() ===
                        selectedFrame.tokenID.toString() &&
                      content.contract.id.toUpperCase() ===
                        selectedFrame.contract.id.toUpperCase()
                    ) {
                      notification.error('Cannot Frame a Frame inside itself!')
                      return
                    }
                    if (selectedFrameMetadata) {
                      const framedContentInfo = selectedFrameMetadata.attributes.find(
                        attribute => attribute.trait_type === 'Framed Content'
                      )
                      if (framedContentInfo) {
                        const splitValues = framedContentInfo.value.split('/')

                        const contentsTokenId = splitValues[0].trim()
                        const contentsContractAddress = splitValues[1].trim()

                        if (
                          content.tokenID.toString() ===
                            contentsTokenId.toString() &&
                          content.contract.id.toUpperCase() ===
                            contentsContractAddress.toUpperCase()
                        ) {
                          notification.error(
                            'Frame already contains this contents!'
                          )
                          return
                        }
                      }
                    }
                    setChainId(1)
                    setSelectedNft(content !== selectedNft ? content : null)
                    setSelectedNftMetadata(
                      content !== selectedNft ? metadata : null
                    )
                  }}
                />
              ) : (
                <NftLoadingView />
              )
            }}
            onSlideVisible={(index, isVisible) => {
              if (isVisible && index >= props.nfts.length - 1) {
                props.canFetchMore &&
                  props.onRequestNext &&
                  props.onRequestNext(props.nfts.length)
              }
            }}
            breakpoints={carouselBreakpoints}
          />
        )}
        {chainId === CHAIN_INFO_BY_NAME.Solana.id ? (
          <SolanaTokenInput
            sx={{
              width: '90%',
              flex: 'none',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto'
            }}
            large={chainId !== 1}
            chainId={chainId}
            onTokenSelected={async (
              contractAddress,
              tokenId,
              nftData,
              nftMetadata,
              chainId
            ) => {
              if (
                selectedFrame &&
                tokenId === selectedFrame.tokenID &&
                contractAddress === selectedFrame.contractAddress
              ) {
                notification.error('Cannot Frame a Frame inside itself!')
              } else {
                setChainId(chainId)
                setSelectedNft(nftData)
                setSelectedNftMetadata(nftMetadata)
              }
            }}
          />
        ) : (
          <TokenInput
            sx={{
              width: '100%',
              ...(chainId !== 1 && {
                width: '90%',
                flex: 'none',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto'
              })
            }}
            large={chainId !== 1}
            chainId={chainId}
            onTokenSelected={async (
              contractAddress,
              tokenId,
              nftData,
              nftMetadata,
              chainId
            ) => {
              if (
                selectedFrame &&
                tokenId === selectedFrame.tokenID &&
                contractAddress === selectedFrame.contractAddress
              ) {
                notification.error('Cannot Frame a Frame inside itself!')
              } else {
                setChainId(chainId)
                setSelectedNft(nftData)
                setSelectedNftMetadata(nftMetadata)
              }
            }}
          />
        )}

        {props.errorLoadingFrames ? (
          <Carousel
            style={{
              flex: 'none',
              width: '100%'
            }}
            centeredSlides={true}
            contents={[props.errorLoadingFrames]}
            onCreateContent={() => {
              return (
                <Typography
                  variant={isMobile ? 'h4' : 'h3'}
                  component='div'
                  align='center'
                  gutterBottom
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 300,
                    textShadow: '2px 2px 5px black',
                    margin: 'auto 0'
                  }}
                >
                  {props.errorLoadingFrames}
                </Typography>
              )
            }}
            breakpoints={carouselBreakpoints}
          />
        ) : (
          <Carousel
            style={{
              flex: 'none',
              width: '100%',
              ...(chainId !== 1 && {
                flex: 'none',
                marginBottom: 'auto'
              })
            }}
            coverflow
            mousewheelEnabled={true}
            freeMode={true}
            centeredSlides={true}
            breakpoints={carouselBreakpoints}
            onCreateContent={nft => {
              let needsRefresh = false
              if (nft === refreshFrame) {
                needsRefresh = true
                setRefreshFrame(null)
              }
              return nft !== LOADING_ID ? (
                <NftContainer
                  nft={nft}
                  needsRefresh={needsRefresh}
                  selected={nft === selectedFrame}
                  onItemClick={(content, metadata) => {
                    if (
                      selectedNft &&
                      content.tokenID.toString() ===
                        selectedNft.tokenID.toString() &&
                      content.contract.id.toUpperCase() ===
                        selectedNft.contract.id.toUpperCase()
                    ) {
                      notification.error('Cannot Frame a Frame inside itself!')
                      return
                    }
                    const framedContentInfo =
                      metadata &&
                      metadata.attributes.find(
                        attribute => attribute.trait_type === 'Framed Content'
                      )
                    if (framedContentInfo) {
                      const splitValues = framedContentInfo.value.split('/')

                      const contentsTokenId = splitValues[0].trim()
                      const contentsContractAddress = splitValues[1].trim()
                      if (
                        selectedNft &&
                        selectedNft.tokenID.toString() ===
                          contentsTokenId.toString() &&
                        selectedNft.contract.id === contentsContractAddress
                      ) {
                        notification.error(
                          'Frame already contains this contents!'
                        )
                        return
                      }
                    }

                    setSelectedFrame(content !== selectedFrame ? content : null)
                    setSelectedFrameMetadata(
                      content !== selectedFrame ? metadata : null
                    )
                  }}
                />
              ) : (
                <NftLoadingView />
              )
            }}
            onSlideVisible={(index, isVisible) => {
              if (isVisible && index >= props.frames.length - 1) {
                props.canFetchMoreFrames &&
                  props.onRequestNextFrames &&
                  props.onRequestNextFrames(props.frames.length)
              }
            }}
            contents={
              props.canFetchMoreFrames
                ? props.frames.concat(loadingPlaceholder)
                : props.frames
            }
          />
        )}
      </LeftContainer>
      <RightContainer
        style={{ backgroundColor: theme.palette.background.paper }}
      >
        <Paper
          elevation={2}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'stretch',
            minWidth: 0,
            height: '100%'
          }}
        >
          {selectedFrame ? (
            <MountedContentPreview
              sx={{
                width: '100%',
                pl: '24px',
                pb: '24px',
                pt: '24px',
                flex: 1
              }}
              chainId={chainId}
              selectedFrame={selectedFrame}
              selectedFrameMetadata={selectedFrameMetadata}
              selectedNft={selectedNft}
              selectedNftMetadata={selectedNftMetadata}
              onUpdateDimensions={(width, height) => {
                setFrameDimensions({ width, height })
              }}
            />
          ) : (
            <DefaultContainer
              sx={{
                flex: 1,
                display: 'block flex',
                width: '100%',
                paddingLeft: '24px',
                paddingRight: '24px',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant={isMobile ? 'h4' : 'h3'}
                component='div'
                align='center'
                gutterBottom
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 300,
                  textShadow: '2px 2px 5px black',
                  margin: 'auto 0'
                }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                Select an NFT and a FRAME
              </Typography>
            </DefaultContainer>
          )}
          <PreviewDetails
            sx={{ width: '100%' }}
            mountLoading={isMounting || isMountingContents}
            unmountLoading={isUnmounting || isUnmountingContents}
            bindLoading={isBinding}
            bindStatusLoading={isCheckingBoundToFrame}
            bindStatus={bindStatus}
            onUnmountClick={async () => {
              setIsUnmounting(true)
              try {
                const messageToSign = JSON.stringify({
                  frameId: selectedFrame.tokenID
                })

                const data = await signMessage(library, account, messageToSign)

                notification.promise(
                  deleteUnmountFrame({
                    frameId: selectedFrame.tokenID,
                    signer: account,
                    signature: data.signature
                  }),
                  {
                    loading: `Unmounting content from ${selectedFrameMetadata.name}...`,
                    success: () => {
                      setRefreshFrame(selectedFrame)
                      return `Successfully unmounted ${selectedFrameMetadata.name}`
                    },
                    error: error => {
                      if (error.message.indexOf('User denied') != -1)
                        return 'You rejected the transaction!'
                      return `Sorry, the transaction failed: ${error.name}`
                    }
                  }
                )
              } catch (error) {
                notification.error(
                  error.message.indexOf('User denied') != -1
                    ? 'Unmount cancelled'
                    : `Sorry, there was an error: ${error.name}`
                )
              }
              setIsUnmounting(false)
            }}
            onMountClick={async () => {
              setIsMounting(true)
              setSignedMessage(null)
              try {
                const messageToSign = JSON.stringify({
                  frameId: selectedFrame.tokenID,
                  tokenId: selectedNft.tokenID.toString(),
                  tokenAddress: selectedNft.contract.id,
                  width: frameDimensions ? parseInt(frameDimensions.width) : 0,
                  height: frameDimensions ? parseInt(frameDimensions.height) : 0
                })

                if (WEB3_READONLY_RPCS[chainId].requiresSecondSignature) {
                  const crossChainData = await signSolanaMessage(messageToSign)
                  setCrossChainAccount(crossChainData.account)
                  console.log(crossChainData)
                  setCrossChainSignedMessage(crossChainData.signature)
                } else {
                  setCrossChainAccount(null)
                  setCrossChainSignedMessage(null)
                }

                const data = await signMessage(library, account, messageToSign)
                if (notificationId) {
                  notification.dismiss(notificationId)
                }

                // const newNotificationId = notification.loading(
                //   'Mounting ' +
                //     selectedNftMetadata.name +
                //     ' to ' +
                //     selectedFrameMetadata.name +
                //     '...'
                // )
                // console.log(newNotificationId)

                // setNotificationId(newNotificationId)

                setSignedMessage(data.signature)
              } catch (e) {
                console.error('error', e)
              }

              setIsMounting(false)
            }}
            onBindClick={executeBind}
            selectedNft={selectedNft}
            selectedNftMetadata={selectedNftMetadata}
            selectedFrame={selectedFrame}
            selectedFrameMetadata={selectedFrameMetadata}
          />
        </Paper>
      </RightContainer>
    </Paper>
  )
}
