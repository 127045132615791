import {
  NUMBER_VARIANTS_RIMS,
  ID_PREFIX_RIMS,
  CHANCE_OF_RIMS
} from './FrameSvgConstants'

export const rimStyleForTrait = (rimStyle = 0) => {
  return rimStyle <= CHANCE_OF_RIMS
    ? ID_PREFIX_RIMS.concat(rimStyle % NUMBER_VARIANTS_RIMS)
    : null
}
