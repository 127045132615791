import './App.css'

import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Element as ScrollElement, scroller, animateScroll } from 'react-scroll'
import Box from '@mui/material/Box'
import _ from 'underscore'
import FramesHero from './sections/FramesHero'
import { Parallax } from 'react-parallax'
import FramesParallaxCarousel from './bg/FramesParallaxCarousel'
import FramesCarousel from './bg/FramesCarousel'
import DescriptionWithImage from './components/DescriptionWithImage'
import DefaultContainer from './components/DefaultContainer'
import EditSvgMenu from './components/EditSvgMenu'
import Roadmap from './sections/Roadmap'
import FaqSection from './sections/Faq'
import NumbersSection from './sections/Numbers'
import FeaturedSection from './sections/Featured'
import { constructDemoSvgString } from './usecase/ConstructDemoSvgStringUsecase'

import STYLE_OPTIONS from './framepieces/styleOptions'

import FrameExample from './assets/images/frame-example.png'
import LevelUpYourLookBg from './assets/images/backgrounds/cyber_structure_glow_darker_cyan.png'
import LevelUpYourValueBg from './assets/images/backgrounds/opal.jpg'
import NumbersBg from './assets/images/backgrounds/road_bg.jpg'
import MiasMetamorphosis from './assets/images/frames/examples/Kintsugi_mias_metamorphosis.png'
import ExampleFrame1 from './assets/images/frames/Kintsugi.png'
import ExampleFrame2 from './assets/images/frames/Speakers.png'
import ExampleFrame3 from './assets/images/frames/Hexagons.png'
import ExampleFrame4 from './assets/images/frames/Cityscape.png'
import ExampleFrame5 from './assets/images/frames/Tiger.png'
import ExampleFrame8 from './assets/images/frames/Weave3.png'

import FramedNft1 from './assets/images/frames/examples/punks.png'
import FramedNft2 from './assets/images/frames/examples/beeple.png'
import FramedNft3 from './assets/images/frames/examples/bamc.png'
import FramedNft4 from './assets/images/frames/examples/hashmasks.png'
import FramedNft5 from './assets/images/frames/examples/bayc.png'
import FramedNft6 from './assets/images/frames/examples/xcopy.png'
import FramedNft7 from './assets/images/frames/examples/gauntlets.png'
import FramedNft8 from './assets/images/frames/examples/meebits.png'
import FramedNft9 from './assets/images/frames/examples/parallel.png'
import FramedNft10 from './assets/images/frames/examples/sevens.png'

const MAX_WIDTH = 1200
const MAX_WIDTH_HERO = 1500

function App () {
  const [svgString, setSvgString] = useState(null)

  const location = useLocation()

  const [mainColour, setMainColour] = useState('#00eeff')
  const [accentColour, setAccentColour] = useState('#FF00ff')
  const [selectedStyle, setSelectedStyle] = useState(
    STYLE_OPTIONS['Cyberpunk Hexagon']
  )

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const loadFrames = useCallback(async () => {
    //load example frame with video inside
    const videoUrl = 'nft_video.mp4'
    const width = 1920,
      height = 1080

    const newSvg = await constructDemoSvgString(
      videoUrl,
      height,
      width,
      mainColour,
      accentColour,
      selectedStyle.corner,
      selectedStyle.tile
    )

    setSvgString(newSvg)
  }, [mainColour, accentColour, selectedStyle])

  const lazySetMainColour = _.debounce(color => {
    setMainColour(color)
  }, 300)
  const lazySetAccentColour = _.debounce(color => {
    setAccentColour(color)
  }, 300)
  const lazySetStyle = _.debounce(style => {
    setSelectedStyle(style)
  }, 50)

  useEffect(() => {
    async function onLoad () {
      loadFrames()
    }

    onLoad()
  }, [mainColour, accentColour, selectedStyle, loadFrames])

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1)
      if (section) {
        scroller.scrollTo(section, { smooth: true })
      }
    } else {
      animateScroll.scrollToTop()
    }
  }, [location])

  return (
    <Box>
      <ScrollElement name='home'>
        <FramesHero maxContentWidth={`${MAX_WIDTH_HERO}px`} />
      </ScrollElement>
      <ScrollElement name='details'>
        <DefaultContainer
          className='bgGlow'
          style={{
            marginTop: isMobile ? '48px' : '148px',
            maxWidth: `${MAX_WIDTH}px`,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <DescriptionWithImage reversed imageSrc={FrameExample}>
            Frames by MurAll are a collection of revolutionary NFTs which allow
            collectors to mount their existing NFTs of any size in a decorative,
            rare and highly collectible frame.
            <br />
            <br />
            Using our expertise in technology and design, Frames strike the
            delicate balance between functionality and beauty.
          </DescriptionWithImage>
        </DefaultContainer>
      </ScrollElement>

      {!isMobile ? (
        <FramesParallaxCarousel
          parallaxValue={isMobile ? -450 : -650}
          slidesPerView={2.55}
          spaceBetween={25}
          images={[
            ExampleFrame1,
            ExampleFrame2,
            ExampleFrame3,
            ExampleFrame4,
            ExampleFrame5
          ]}
          style={{
            width: '100%',
            height: '306px',
            margin: 0,
            marginTop: isMobile ? '48px' : '148px',
            marginBottom: '10%',
            padding: 0
          }}
          breakpoints={{
            '@0.25': {
              slidesPerView: 1.15,
              spaceBetween: 75
            },
            '@0.5': {
              slidesPerView: 1.5,
              spaceBetween: 20
            },
            '@0.75': {
              slidesPerView: 1.65,
              spaceBetween: 70
            },
            '@1.00': {
              slidesPerView: 2.25,
              spaceBetween: 70
            },
            '@1.25': {
              slidesPerView: 2.55,
              spaceBetween: 70
            },
            '@1.50': {
              slidesPerView: 2.95,
              spaceBetween: 70
            },
            '@2': {
              slidesPerView: 3.55,
              spaceBetween: 70
            },
            '@2.5': {
              slidesPerView: 4.15,
              spaceBetween: 70
            },
            '@3': {
              slidesPerView: 5.05,
              spaceBetween: 70
            }
          }}
        />
      ) : (
        <FramesCarousel
          itemMargin={25}
          slidesPerView={1.45}
          images={[
            ExampleFrame1,
            ExampleFrame2,
            ExampleFrame3,
            ExampleFrame4,
            ExampleFrame5
          ]}
          style={{
            width: '100%',
            height: '206px',
            margin: 0,
            marginTop: '48px',
            marginBottom: '10%',
            padding: 0
          }}
        />
      )}

      {/** Level up your look *******************************************/}
      <Parallax
        className='framesSlantPx'
        blur={{ min: 0, max: 2 }}
        bgImage={LevelUpYourLookBg}
        bgImageAlt='cyberpunk background'
        strength={400}
      >
        <DefaultContainer
          style={{
            marginTop: '148px',
            flexDirection: 'column',
            maxWidth: `${MAX_WIDTH}px`,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <DescriptionWithImage
            svg
            title={'Level up your look'}
            imageSrc={svgString}
            extraContent={
              <EditSvgMenu
                style={{
                  alignSelf: 'flex-start',
                  margin: '24px',
                  textAlign: 'center'
                }}
                styleOptions={STYLE_OPTIONS}
                onStyleChanged={style => {
                  lazySetStyle(style)
                }}
                mainColor={mainColour}
                accentColor={accentColour}
                selectedStyle={selectedStyle}
                onMainColorChanged={color => lazySetMainColour(color)}
                onAccentColorChanged={color => lazySetAccentColour(color)}
              />
            }
          >
            Just like when you buy a physical work of art, you buy a frame to
            complete the look, our frames will enhance your chosen NFT to make
            it truly eye catching.
          </DescriptionWithImage>
        </DefaultContainer>

        {!isMobile ? (
          <FramesParallaxCarousel
            parallaxValue={600}
            slidesPerView={2.55}
            spaceBetween={25}
            itemMargin={70}
            height={406}
            images={[
              FramedNft1,
              FramedNft2,
              FramedNft3,
              FramedNft4,
              FramedNft5,
              FramedNft6,
              FramedNft7,
              FramedNft8,
              FramedNft9,
              FramedNft10
            ]}
            style={{
              width: '100%',
              height: '406px',
              margin: 0,
              marginTop: isMobile ? '48px' : '148px',
              marginBottom: '10%'
            }}
            breakpoints={{
              '@0.25': {
                slidesPerView: 1.25,
                spaceBetween: 55
              },
              '@0.5': {
                slidesPerView: 1.65,
                spaceBetween: 75
              },
              '@0.75': {
                slidesPerView: 2.35,
                spaceBetween: 70
              },
              '@1.00': {
                slidesPerView: 2.95,
                spaceBetween: 70
              },
              '@1.25': {
                slidesPerView: 3.25,
                spaceBetween: 70
              },
              '@1.50': {
                slidesPerView: 3.55,
                spaceBetween: 70
              },
              '@1.75': {
                slidesPerView: 3.85,
                spaceBetween: 70
              },
              '@2': {
                slidesPerView: 4.25,
                spaceBetween: 70
              },
              '@2.5': {
                slidesPerView: 4.55,
                spaceBetween: 70
              },
              '@3': {
                slidesPerView: 5.55,
                spaceBetween: 70
              }
            }}
          />
        ) : (
          <FramesCarousel
            itemMargin={25}
            slidesPerView={1.85}
            images={[
              FramedNft1,
              FramedNft2,
              FramedNft3,
              FramedNft4,
              FramedNft5,
              FramedNft6,
              FramedNft7,
              FramedNft8,
              FramedNft9,
              FramedNft10
            ]}
            style={{
              width: '100%',
              height: '206px',
              margin: 0,
              marginTop: '48px',
              marginBottom: '20%',
              padding: 0
            }}
          />
        )}
      </Parallax>
      <DefaultContainer
        className='bgGlow'
        style={{
          marginTop: '148px',
          maxWidth: `${MAX_WIDTH}px`,
          marginBottom: '148px',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <DescriptionWithImage
          reversed
          title={'Level up your experience'}
          imageSrc={ExampleFrame8}
        >
          Frames holders will gain access to an exclusive community of
          collectors, with competitions and perks including access to the VIP
          floors in the upcoming MurAll headquarters in Decentraland.
          <br></br>
          <br></br>
          As a bonus, Frame holders will be able to personalise their experience
          on the MurAll dApp by viewing the live state, history, and their own
          personal gallery of MurAll NFTs inside their Frame.
        </DescriptionWithImage>
      </DefaultContainer>

      <Parallax
        className='framesSlantPx'
        blur={{ min: 1, max: 2 }}
        bgImage={LevelUpYourValueBg}
        bgImageAlt='the dog'
        strength={600}
        style={{
          marginTop: isMobile ? '48px' : '148px'
        }}
      >
        <DefaultContainer
          style={{
            marginTop: '248px',
            maxWidth: `${MAX_WIDTH}px`,
            marginBottom: '248px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <DescriptionWithImage
            title={'Level up your value'}
            imageSrc={MiasMetamorphosis}
          >
            By compounding the rarity of your existing NFT and the rarity of the
            frame itself, Frames will enable holder to leverage this when
            holding/selling. A rare artwork in a rare, first-of-its-kind frame
            will bring value to any holder’s collection.
          </DescriptionWithImage>
        </DefaultContainer>
      </Parallax>

      <DefaultContainer
        className='bgGlow'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '48px',
          maxWidth: `${MAX_WIDTH}px`
        }}
      >
        <DescriptionWithImage
          reversed
          title='A portal across chains'
          imageSrc='XCHAIN.gif'
        >
          Why should your NFTs on other chains miss out on the vast Ethereum NFT
          metaverse? We believe the future is multi-chain, which is why Frames
          not only can display your Ethereum NFTs but also your NFTs from
          Polygon, Solana and Binance Smart Chain, with more chains being added
          as we continue developing the Frames ecosystem.
        </DescriptionWithImage>
      </DefaultContainer>

      <Parallax
        className='framesSlantPx'
        blur={{ min: 1.5, max: 3 }}
        bgImage={NumbersBg}
        bgImageAlt='the dog'
        strength={400}
      >
        <ScrollElement name='numbers'>
          <NumbersSection />
        </ScrollElement>

        <ScrollElement name='roadmap'>
          <Roadmap />
        </ScrollElement>
      </Parallax>

      <ScrollElement name='faq'>
        <FaqSection />
      </ScrollElement>

      <FeaturedSection />
    </Box>
  )
}

export default App
