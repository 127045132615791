import React from 'react'
import styled from 'styled-components'
import Davatar from '@davatar/react'
import { useWeb3React } from '@web3-react/core'
import WalletConnectIcon from '../../../assets/images/logos/wallet-connect.svg'
import { injected, walletconnect } from '../../../lib/connectors'

const WalletIcon = () => {
  const { connector } = useWeb3React()
  
  if (connector === injected) {
    return (
      <IconWrapper size={16}>
        <InjectedIcon />
      </IconWrapper>
    )
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={WalletConnectIcon} alt={'WalletConnect logo'} />
      </IconWrapper>
    )
  }
  return null
}

const InjectedIcon = () => {
  const { account, library } = useWeb3React()
  return (
    <InjectedIconContainer>
      {account && <Davatar address={account} size={16} provider={library?.provider} />}
    </InjectedIconContainer>
  )
}

const InjectedIconContainer = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

const IconWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => size ? `${size} px` : '32px'};
    width: ${({ size }) => size ? `${size} px` : '32px'};
  }
`

export default WalletIcon
