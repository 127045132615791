import { injected, walletconnect} from './index'
import injectedIcon from '../../assets/images/logos/injected.svg'
import metamaskIcon from '../../assets/images/logos/metamask.png'
import walletConnectIcon from '../../assets/images/logos/wallet-connect.svg'

const wallets = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    icon: injectedIcon,
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    icon: metamaskIcon,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    icon: walletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  }
}

export default wallets
