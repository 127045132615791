import ERC1155 from '../assets/contracts/ERC1155.json'
import axios from 'axios'
import { replaceTokenIdIfNeeded, replaceIpfsIfNeeded } from '../lib/helpers.js'

export const getBalanceForAddress = (
  web3,
  erc1155TokenContractAddress,
  address,
  tokenId
) => {
  return getErc1155ContractInstance(web3, erc1155TokenContractAddress)
    .methods.balanceOf(address, tokenId)
    .call()
}

export const getTokenUri = (web3, erc1155TokenContractAddress, tokenId) => {
  return getErc1155ContractInstance(web3, erc1155TokenContractAddress)
    .methods.uri(tokenId)
    .call()
}

export const setApprovedForAll = (
  web3,
  erc1155TokenContractAddress,
  account,
  spenderAddress,
  approved
) => {
  return getErc1155ContractInstance(web3, erc1155TokenContractAddress)
    .methods.setApprovalForAll(spenderAddress, approved)
    .send({ from: account })
}

export const isApprovedForAll = (
  web3,
  erc1155TokenContractAddress,
  account,
  spenderAddress
) => {
  return getErc1155ContractInstance(web3, erc1155TokenContractAddress)
    .methods.isApprovedForAll(account, spenderAddress)
    .call()
}

export const getOffchainRemoteData = (
  web3,
  erc1155TokenContractAddress,
  tokenId
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const url = await getTokenUri(web3, erc1155TokenContractAddress, tokenId)
      const res = await fetch(url)
      const tokenInfo = await res.json()
      resolve(tokenInfo)
    } catch (error) {
      console.error(
        'failed to get erc1155 ' + erc1155TokenContractAddress + ' ' + tokenId
      )
      reject(error)
    }
  })
}

export const getFullTokenUriMetadata = async (
  web3,
  tokenContractAddress,
  tokenId
) => {
  let tokenUri = await getTokenUri(web3, tokenContractAddress, tokenId)

  tokenUri = replaceTokenIdIfNeeded(web3.utils, tokenUri, tokenId)

  tokenUri = replaceIpfsIfNeeded(tokenUri)

  if (tokenUri.indexOf('http') === -1) {
    tokenUri = 'https://' + tokenUri
  }

  let returnData
  if (tokenUri && tokenUri.startsWith('https://data:application/json;base64')) {
    // 29 = length of "data:application/json;base64,"
    const json = Buffer.from(
      tokenUri.replace('https://data:application/json;base64', ''),
      'base64'
    )
    returnData = JSON.parse(json)
  } else {
    let config = {
      // headers: {
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Headers': '*',
      //   'Access-Control-Allow-Credentials': 'true'
      // }
    }
    const result = await axios.get(tokenUri, config)
    returnData = result.data
  }

  return returnData
}

const getErc1155ContractInstance = (web3, erc1155TokenContractAddress) => {
  return new web3.eth.Contract(ERC1155.abi, erc1155TokenContractAddress)
}
