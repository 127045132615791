import React from 'react'
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { grey } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#121212'
    },
    primary: {
      main: '#7E0AE5',
      dark: '#1A1A1A',
      contrastText: '#fff' //button text white instead of black
    },
    secondary: {
      main: '#26262B',
      contrastText: '#fff' //button text white instead of black
    },
    text: {
      primary: '#fff',
      secondary: grey[500]
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1324,
        xl: 2024
      }
    }
  },
  colors: {
    named: {
      red: '#ff1919',
      green: '#27AE60'
    }
  }
})

const FramesThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          {children}
        </StyledThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default FramesThemeProvider
