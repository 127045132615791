import React from 'react'
import { useWeb3React } from '@web3-react/core'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { openInNewTab } from '../../../../lib/utils'
import { SupportedChain } from '../../../../lib/constants'

const EXPLORER_URLS = {
  [SupportedChain.Ethereum]: 'https://etherscan.io',
  [SupportedChain.Ropsten]: 'https://ropsten.etherscan.io',
  [SupportedChain.Rinkeby]: 'https://rinkeby.etherscan.io',
  [SupportedChain.Goerli]: 'https://goerli.etherscan.io'
}

const MintSuccess = ({ result, showForm, onOk }) => {
  const { chainId } = useWeb3React()

  if (!result || showForm) return null

  return (
    <Alert
      severity='success'
      action={
        <Button onClick={onOk} color='inherit' size='small'>
          OK
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>You successfully minted your Frames!</AlertTitle>
      <Button
        aria-label='view-etherscan'
        size='small'
        color='inherit'
        endIcon={<OpenInNewOutlinedIcon fontSize='small' />}
        onClick={() => openInNewTab(`${EXPLORER_URLS[chainId]}/tx/${result.transactionHash}`)}
      >
        View tx on Etherscan
      </Button>
    </Alert>
  )
}

export default MintSuccess
