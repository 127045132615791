import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(4)};
  margin-right: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ glow }) => glow && `&:before {
    height: 100%;
    top: -5%;
    background-image: radial-gradient(closest-side, #e91bfa4d 0%, transparent 65%);
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    mix-blend-mode: screen;
    z-index: -100;
    overflow: hidden;
  };`}
`

export default Container
