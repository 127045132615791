import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Box, Grid, Button, IconButton } from '@mui/material'
import { useTheme } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import OpenSeaLogo from '../../../assets/images/logos/opensea_logo_white.svg'
import { Icon } from '@mui/material'
import styled from 'styled-components'
import { amber, orange } from '@mui/material/colors'
import LaunchIcon from '@mui/icons-material/Launch'
import Tooltip from '@mui/material/Tooltip'
import { getTokenMetadata } from '../../../usecase/GetTokenMetadataUseCase'
import { CHAIN_INFO_BY_NAME, WEB3_READONLY_RPCS } from '../../../lib/constants'
import { getDefaultExternalTokenUrlForChain } from '../../../lib/helpers'
import { useActiveWeb3React } from '../../../hooks/web3'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Skeleton from '@mui/material/Skeleton'

const BG_NO_CONTENT =
  'linear-gradient(30deg, #1c1c1c 0%, #202020 16%, #232323 33%, #272727 50%, #2b2b2b 66%, #2f2f2f 83%, #333333 100%)'
const TITLE_NO_CONTENT = 'No Content'
const TITLE_MOUNTED_CONTENT = 'Mounted Content'
const TITLE_BOUND_CONTENT = 'Bound Content'
const BOUND_GRADIENT =
  'linear-gradient(90deg, rgba(49,254,1,1) 0%, rgba(255,255,26,1) 10%, rgba(233,27,250,1) 50%, rgba(28,211,207,1) 100%)'
const BG_FRAMED_CONTENT =
  'linear-gradient(30deg, #000000 0%, #040504 16%, #080b08 33%, #0c0f0c 50%, #101310 66%, #121612 83%, #151915 100%)'
const MOUNTED_GRADIENT = 'linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%)'

const ShadowTypography = styled(Typography)`
  text-shadow: 2px 2px 5px black;
`
const RainbowStrokeTypography = styled(Typography)`
  background: linear-gradient(
    90deg,
    rgba(49, 254, 1, 1) 0%,
    rgba(255, 255, 26, 1) 10%,
    rgba(233, 27, 250, 1) 50%,
    rgba(28, 211, 207, 1) 100%
  );
  background-size: 200% 200%;
  -webkit-text-stroke: 4px transparent;
  -webkit-background-clip: text;
  text-shadow: 2px 2px 5px black;
`

export default function FramedContentDetails (props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { library } = useActiveWeb3React()

  const [frameLink, setFrameLink] = useState(null)
  const [framedContentLink, setFramedContentLink] = useState(null)
  const [framedContentTitle, setFramedContentTitle] = useState(null)
  const [framedContentName, setFramedContentName] = useState(null)
  const [framedContentDescription, setFramedContentDescription] = useState(null)
  const [framedContentColor, setFramedContentColor] = useState(BG_NO_CONTENT)
  const [needsExpansion, setNeedsExpansion] = useState(false)
  const [expandDescription, setExpandDescription] = useState(false)
  const [loadingMetadata, setLoadingMetadata] = useState(false)
  const [contentChainInfo, setContentChainInfo] = useState(
    WEB3_READONLY_RPCS[1]
  )

  useEffect(() => {
    async function onFrameMetadataUpdated () {
      if (!props.frameMetadata) return
      setFramedContentTitle(TITLE_NO_CONTENT)
      setFramedContentName(null)
      setFramedContentDescription(null)
      setFramedContentColor(BG_NO_CONTENT)
      setNeedsExpansion(false)
      setFrameLink(
        `https://opensea.io/assets/${props.frame.contract.id}/${props.frame.tokenID}`
      )
      props.frameMetadata.attributes.forEach(attribute => {
        if (attribute.trait_type === 'Framing Method') {
          setFramedContentTitle(
            attribute.value === 'Mounted'
              ? TITLE_MOUNTED_CONTENT
              : TITLE_BOUND_CONTENT
          )

          setFramedContentColor(BG_FRAMED_CONTENT)
        }
        if (attribute.trait_type === 'Framed Content') {
          const splitValues = attribute.value.split('/')

          const tokenId = splitValues[0].trim()
          const contractAddress = splitValues[1].trim()

          const chainAttribute = props.frameMetadata.attributes.find(
            attribute => attribute.trait_type === 'Framed Content Chain'
          )
          const chainInfo = chainAttribute
            ? CHAIN_INFO_BY_NAME[chainAttribute.value]
            : CHAIN_INFO_BY_NAME.Ethereum
          setContentChainInfo(chainInfo)
          setLoadingMetadata(true)
          getTokenMetadata(library, contractAddress, tokenId, chainInfo.id)
            .then(metadata => {
              setFramedContentName(metadata.name)
              setFramedContentDescription(
                metadata.description ? metadata.description : contractAddress
              )
              setNeedsExpansion(
                metadata.description ? metadata.description.length > 300 : false
              )
              setFramedContentLink(
                metadata.external_url
                  ? metadata.external_url
                  : getDefaultExternalTokenUrlForChain(
                      splitValues[1].trim(),
                      splitValues[0].trim(),
                      chainInfo.id
                    )
              )
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              setLoadingMetadata(false)
            })
        }
      })
    }

    onFrameMetadataUpdated()
  }, [props.frameMetadata])

  const styleSx =
    framedContentTitle !== TITLE_NO_CONTENT
      ? {
          borderRadius: '5px',
          background:
            framedContentColor +
            ' padding-box,' +
            (framedContentTitle === TITLE_BOUND_CONTENT
              ? BOUND_GRADIENT
              : MOUNTED_GRADIENT) +
            ' border-box',
          border: '3px solid transparent'
        }
      : {
          background: framedContentColor,
          borderRadius: '5px',
          borderColor: '#ffffff',
          borderWidth: '2px',
          borderStyle: 'solid'
        }

  const createFramedContentsDetails = () => (
    <Box
      sx={{
        ...styleSx,
        display: 'block',
        marginBottom: '48px',
        ...(!needsExpansion && {
          pb: { xs: '12px', sm: '24px' }
        }),
        pl: { xs: '12px', sm: '24px' },
        pr: { xs: '12px', sm: '24px' },
        pt: { xs: '12px', sm: '24px' }
      }}
    >
      {framedContentTitle === TITLE_NO_CONTENT ? (
        <ShadowTypography
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 300
          }}
          align={'center'}
          variant={isMobile ? 'h6' : 'h5'}
        >
          {framedContentTitle}
        </ShadowTypography>
      ) : (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{ flex: 1 }}
        >
          <Grid
            item
            container
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='center'
            xs
          >
            <Grid
              container
              item
              xs={12}
              spacing={1}
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid item>
                <Tooltip
                  placement='top'
                  arrow
                  title={`Content is on ${contentChainInfo.name}`}
                >
                  <Icon>
                    <img
                      alt={`Content is on ${contentChainInfo.name}`}
                      src={contentChainInfo.logo}
                    />
                  </Icon>
                </Tooltip>
              </Grid>
              {framedContentTitle === TITLE_BOUND_CONTENT ? (
                <Grid item>
                  <RainbowStrokeTypography
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 300
                    }}
                    align={'center'}
                    variant={isMobile ? 'h6' : 'h5'}
                  >
                    {framedContentTitle}
                  </RainbowStrokeTypography>
                </Grid>
              ) : (
                <Grid item>
                  <ShadowTypography
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 300
                    }}
                    color={orange[300]}
                    align={'center'}
                    variant={isMobile ? 'h6' : 'h5'}
                  >
                    {framedContentTitle}
                  </ShadowTypography>
                </Grid>
              )}
              <Grid item>
                <Tooltip
                  placement='top'
                  arrow
                  title={`View content externally`}
                >
                  <IconButton
                    aria-label='delete'
                    size='large'
                    onClick={() => {
                      window.open(framedContentLink, '_blank')
                    }}
                  >
                    <Icon>
                      <LaunchIcon />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {loadingMetadata ? (
              <Grid item xs={12}>
                <Skeleton
                  variant='rectangular'
                  style={{
                    width: '100%',
                    height: 100
                  }}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <ShadowTypography
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 300
                    }}
                    align={'center'}
                    variant={isMobile ? 'h5' : 'h4'}
                  >
                    {framedContentName}
                  </ShadowTypography>
                </Grid>
                <Grid item xs={12}>
                  {needsExpansion ? (
                    <>
                      <Collapse in={expandDescription} collapsedSize={100}>
                        <ShadowTypography
                          sx={{
                            fontFamily: 'Roboto',
                            fontWeight: 300
                          }}
                          align={'center'}
                          variant={'subtitle1'}
                        >
                          {framedContentDescription}
                        </ShadowTypography>
                      </Collapse>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <IconButton
                          aria-label='delete'
                          size='large'
                          style={{
                            marginTop: '-5px'
                          }}
                          onClick={() => {
                            setExpandDescription(!expandDescription)
                          }}
                        >
                          <Icon>
                            <KeyboardArrowDownIcon
                              style={{
                                transition: 'all 300ms',
                                transform: expandDescription
                                  ? 'rotate(180deg)'
                                  : ''
                              }}
                            />
                          </Icon>
                        </IconButton>
                      </Box>
                    </>
                  ) : (
                    <ShadowTypography
                      sx={{
                        fontFamily: 'Roboto',
                        fontWeight: 300
                      }}
                      align={'center'}
                      variant={'subtitle1'}
                    >
                      {framedContentDescription}
                    </ShadowTypography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  )

  return (
    <Card
      elevation={0}
      sx={{
        ...(props.sx
          ? {
              ...props.sx
            }
          : {}),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent'
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'stretch',
          width: '100%',
          padding: '0px'
        }}
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          spacing={0}
          sx={{
            width: '100%',
            pb: { xs: '12px', sm: '24px', md: '36px' }
          }}
        >
          <Grid item xs={12}>
            {createFramedContentsDetails()}
          </Grid>
          {frameLink && (
            <Grid item xs>
              <Button
                size='large'
                variant='contained'
                onClick={() => {
                  window.open(frameLink, '_blank')
                }}
                startIcon={
                  <Icon>
                    <img alt={'opensea'} src={OpenSeaLogo} />
                  </Icon>
                }
                style={{ width: '100%', backgroundColor: '#2081E2' }}
              >
                View on OpenSea
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
