import Web3 from 'web3'
import { CHAIN_INFO_BY_NAME } from '../lib/constants'

const MAX_LENGTH = 44
const MIN_LENGTH = 43

var BASE58_ALPHABET =
  '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'
var BASE58_ALPHABET_MAP = {}
for (var i = 0; i < BASE58_ALPHABET.length; ++i) {
  BASE58_ALPHABET_MAP[BASE58_ALPHABET.charAt(i)] = i
}
var BASE = BASE58_ALPHABET.length

export const isValidAddress = (address, chainId) => {
  if (!address) {
    return false
  }
  if (chainId === CHAIN_INFO_BY_NAME.Solana.id) {
    return isValidSolanaAddress(address)
  } else {
    return Web3.utils.isAddress(address)
  }
}

const isValidSolanaAddress = address => {
  try {
    if (!address || address.length == 0) {
      return false
    }

    if (address.length < MIN_LENGTH) {
      return false
    }

    if (address.length > MAX_LENGTH) {
      return false
    }
    try {
      const decoded = decodeBase58(address)
      if (!decoded || !decoded.length) {
        return false
      }
    } catch (e) {
      // if decoding fails, assume invalid address
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

const decodeBase58 = string => {
  if (string.length === 0) return []

  var i,
    j,
    bytes = [0]
  for (i = 0; i < string.length; ++i) {
    var c = string[i]
    if (!(c in BASE58_ALPHABET_MAP)) throw new Error('Non-base58 character')

    for (j = 0; j < bytes.length; ++j) bytes[j] *= BASE
    bytes[0] += BASE58_ALPHABET_MAP[c]

    var carry = 0
    for (j = 0; j < bytes.length; ++j) {
      bytes[j] += carry
      carry = bytes[j] >> 8
      bytes[j] &= 0xff
    }

    while (carry) {
      bytes.push(carry & 0xff)
      carry >>= 8
    }
  }
  // deal with leading zeros
  for (i = 0; string[i] === '1' && i < string.length - 1; ++i) {
    bytes.push(0)
  }

  return bytes.reverse()
}
