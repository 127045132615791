import {
  FRAME_RIM_HEIGHT,
  FRAME_RIM_WIDTH,
  CORNER_PIECE_HEIGHT,
  SVG_TAG_END,
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  PLAQUE_ID,
  PLAQUE_HEIGHT,
  PLAQUE_WIDTH,
  CORNER_PIECE_ID,
  TILE_PIECE_ID,
  ANIMATION_ID
} from '../traitsystem/FrameSvgConstants'

export const constructSvgString = (
  centerImage = null,
  imageHeight = IMAGE_HEIGHT,
  imageWidth = IMAGE_WIDTH,
  mimeType,
  baseSvgString
) => {
  const percentBigger = imageHeight / IMAGE_HEIGHT
  imageHeight = imageHeight / percentBigger
  imageWidth = imageWidth / percentBigger

  const { frameHeight, frameWidth } = calculateFrameDimensions(
    imageHeight,
    imageWidth
  )

  const svgString = `${constructStartTag(frameWidth, frameHeight)}
  ${baseSvgString}
  ${
    centerImage
      ? mimeType.startsWith('video')
        ? constructCenterVideo(
            centerImage,
            frameWidth,
            frameHeight,
            FRAME_RIM_HEIGHT
          )
        : mimeType.startsWith('image')
        ? constructCenterImage(
            centerImage,
            frameWidth,
            frameHeight,
            FRAME_RIM_HEIGHT
          )
        : constructCenterIframe(
            centerImage,
            frameWidth,
            frameHeight,
            FRAME_RIM_HEIGHT
          )
      : ''
  }
  ${constructTop(frameWidth, frameHeight, TILE_PIECE_ID)}
  ${constructBottom(frameWidth, frameHeight, TILE_PIECE_ID)}
  ${constructLeft(frameWidth, frameHeight, TILE_PIECE_ID)}
  ${constructRight(frameWidth, frameHeight, TILE_PIECE_ID)}   
  ${constructTopLeftCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}    
  ${constructTopRightCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}
  ${constructBottomLeftCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}
  ${constructBottomRightCorner(frameWidth, frameHeight, CORNER_PIECE_ID)}
  ${constructPlaque(frameWidth, frameHeight)}
  ${SVG_TAG_END}
    `

  return svgString
}

const calculateFrameDimensions = (imageHeight, imageWidth) => {
  const frameHeight = imageHeight + FRAME_RIM_HEIGHT * 2
  const frameWidth = imageWidth + FRAME_RIM_HEIGHT * 2
  return {
    frameHeight,
    frameWidth
  }
}

const constructCenterImage = (centerImage, width, height, rimHeight) => {
  return ` 
    <g transform="translate(${rimHeight},${rimHeight})"  >
      <image x="0" y="0" href="${centerImage}" height="${height -
    2 * rimHeight}" width="${width - 2 * rimHeight}"/>
    </g>
    `
}

const constructCenterIframe = (centerImage, width, height, rimHeight) => {
  return `
    <g transform="translate(${rimHeight},${rimHeight})"  >
        <foreignObject x="0" y="0" height="${height -
          2 * rimHeight}" width="${width -
    2 *
      rimHeight}" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
            <iframe height="${height - 2 * rimHeight}" width="${width -
    2 *
      rimHeight}" src="${centerImage}" frameborder="0" style="pointer-events:auto;"></iframe>
        </foreignObject>
    </g>  
    `
}

const constructCenterVideo = (centerImage, width, height, rimHeight) => {
  return `
      <foreignObject x="${rimHeight}" y="${rimHeight}" height="${height -
    FRAME_RIM_WIDTH}" width="${width -
    FRAME_RIM_WIDTH}" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                <video loop="true" muted="true" autoplay="true" playsinline="true" height="${height -
                  FRAME_RIM_WIDTH}" width="${width -
    FRAME_RIM_WIDTH}" controls="true" style="pointer-events:auto;z-index: -1;">
                    <source src="${centerImage}" type="video/mp4"/>
          </video>
       </foreignObject> 
    `
}

const constructStartTag = (width, height) => {
  return `<svg viewBox='0 0 ${width} ${height}' xmlns='http://www.w3.org/2000/svg' xmlns:xlink="http://www.w3.org/1999/xlink"  style="pointer-events:none;">`
}

const constructTop = (width, height, style) => {
  return `<g transform="translate(0,0)">
    ${constructTilePiece(width, height, style, true)}
    </g>`
}
const constructBottom = (width, height, style) => {
  return `<g transform="translate(${width},${height} ) rotate(180 0 0)">
    ${constructTilePiece(width, height, style, true)}
    </g>`
}

const constructLeft = (width, height, style) => {
  return `<g transform="translate(0,${height}) rotate(270 0 0)">
    ${constructTilePiece(width, height, style, false)}
    </g>`
}

const constructRight = (width, height, style) => {
  return `<g transform="translate(${width},0) rotate(90 0 0)">
    ${constructTilePiece(width, height, style, false)}
    </g>`
}

const constructTopLeftCorner = (width, height, style) => {
  return `<g transform="translate(0,0)" style="overflow:visible">
    ${constructCornerPiece(style)}
    </g>`
}
const constructTopRightCorner = (width, height, style) => {
  return `<g transform="translate(${width},0)  rotate(90 0 0)" style="overflow:visible">
    ${constructCornerPiece(style)}
    </g>`
}

const constructBottomLeftCorner = (width, height, style) => {
  return `<g transform="translate(0,${height})  rotate(-90 0 0)" style="overflow:visible">
    ${constructCornerPiece(style)}
    </g>`
}
const constructBottomRightCorner = (width, height, style) => {
  return `<g transform="translate(${width},${height})  rotate(180 0 0)" style="overflow:visible">
    ${constructCornerPiece(style)}
    </g>`
}

const constructCornerPiece = style => {
  return `
    <use x="0" y="0" width="${CORNER_PIECE_HEIGHT}" height="${CORNER_PIECE_HEIGHT}" xlink:href="#${style}" style="overflow:visible"/>
    `
}

const constructTilePiece = (width, height, style, horizontal = false) => {
  return `<rect x="${FRAME_RIM_HEIGHT}" y="0" width="${
    horizontal ? width - FRAME_RIM_WIDTH : height - FRAME_RIM_WIDTH
  }" height="${FRAME_RIM_HEIGHT}"
    style="fill: url(#${style});  filter: url(#${ANIMATION_ID});"/>`
}

const constructPlaque = (width, height) => {
  return `<g transform="translate(${width / 2 - PLAQUE_WIDTH / 2},${height -
    PLAQUE_HEIGHT})">
      <use x="0" y="0" width="${PLAQUE_WIDTH}" height="${PLAQUE_HEIGHT}"
      xlink:href="#${PLAQUE_ID}"/>
    </g>`
}
