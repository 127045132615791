import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { GET_NFTS } from '../graphql/queries'

const GRAPH_API_URL =
  'https://api.thegraph.com/subgraphs/name/murall-art/nft-subgraph'

export const GraphApi = createApi({
  reducerPath: 'graph',
  baseQuery: graphqlRequestBaseQuery({ url: GRAPH_API_URL }),
  endpoints: builder => ({
    getNfts: builder.query({
      query: ({ address, from = 0, limit = 10 }) => ({
        document: GET_NFTS,
        variables: {
          skip: from,
          first: limit,
          owner: address
        }
      }),
      transformResponse: response => response.ownerships
    })
  })
})

export const { useGetNftsQuery } = GraphApi
