import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Layout from './components/common/Layout'
import App from './App'
import MintPage from './pages/Mint'
import PresaleMintPage from './pages/PresaleMint'
import MountPage from './pages/Mount'
import FramePage from './pages/Frame'

const Routes = () => {
  return (
    <Layout>
      <Switch>
        <Route path='/presalemint' component={PresaleMintPage} exact />
        <Route path='/mint' component={MintPage} exact />
        <Route path='/mount' component={MountPage} exact />
        <Route path='/frame/:id'>
          <FramePage />
        </Route>
        <Route path='/' component={App} exact />
        <Redirect to='/' />
      </Switch>
    </Layout>
  )
}

export default Routes
