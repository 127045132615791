import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx' // a utility for constructing className conditionally
import '../../App.css'

function LazyImage (props) {
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef()
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true)
    }
  }, [])
  return (
    <div className={clsx('lazyWrapper')}>
      <img
        style={{
          ...(props.style
            ? {
                ...props.style
              }
            : {})
        }}
        className={clsx('lqip', loaded && 'lqipLoaded')}
        src={props.lqip}
        alt={props.alt}
        aria-hidden='true'
      />
      <img
        style={{
          ...(props.style
            ? {
                ...props.style
              }
            : {})
        }}
        loading='lazy'
        src={props.src}
        alt={props.alt}
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={clsx('lazySource', loaded && 'lazyLoaded')}
      />
    </div>
  )
}
export default LazyImage
