import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Grid, Typography, Divider } from '@mui/material'
import Fade from 'react-reveal/Fade'

export default function InfoGrid (props) {
  const theme = useTheme()
  const createGridTextRow = (key, value, shouldIncludeDivider) => (
    <React.Fragment>
      <Grid item xs={12}>
        {createTextRow(key, value, shouldIncludeDivider)}
      </Grid>
      {shouldIncludeDivider && (
        <Grid item xs={12}>
          <Divider variant='middle' />
        </Grid>
      )}
    </React.Fragment>
  )
  const createTextRow = (key, value) => (
    <Fade top cascade>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 'auto',
          width: '100%',
          justifyContent: 'space-between',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.contrastText,
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            textShadow: '2px 2px 5px black'
          }}
          variant='body'
          component='p'
        >
          {key}:&emsp;
        </Typography>
        <Typography
          variant='body'
          component='p'
          sx={{
            color: theme.palette.primary.contrastText,
            textAlign: 'right',
            fontFamily: 'Roboto',
            fontWeight: 300,
            textShadow: '2px 2px 5px black'
          }}
        >
          {value}
        </Typography>
      </div>
    </Fade>
  )

  return (
    <Grid container spacing={2} px={8} pb={4} direction='column'>
      {Object.keys(props.contents).map(function (keyName, keyIndex) {
        return createGridTextRow(
          keyName,
          props.contents[keyName],
          props.dividers && keyIndex < Object.keys(props.contents).length - 1
        )
      })}
    </Grid>
  )
}
