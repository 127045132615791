/**
   Returns the dimensions of a video asynchrounsly.
  @param {String} url Url of the video to get dimensions from.
  @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
  */
export const getVideoDimensionsOf = url => {
  return new Promise(resolve => {
    // create the video element
    const video = document.createElement('video')
    video.setAttribute('preload', 'metadata')
    // place a listener on it
    video.addEventListener(
      'loadedmetadata',
      function () {
        // retrieve dimensions
        const height = this.videoHeight
        const width = this.videoWidth
        // send back result
        video.remove()
        resolve({ height, width })
      },
      false
    )

    // start download meta-datas
    video.src = url
  })
}

export const getImageDimensionsOf = url => {
  return new Promise(resolve => {
    var img = new Image()
    img.src = url

    var wait = setInterval(function () {
      var w = img.naturalWidth,
        h = img.naturalHeight

      if (w && h) {
        clearInterval(wait)
        img.remove()
        resolve({
          width: w,
          height: h
        })
      }
    }, 30)
  })
}

export const createImgFromSvg = (data, width, height) => {
  return new Promise((res, rej) => {
    const blob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
    let URL = window.URL || window.webkitURL || window
    let blobURL = URL.createObjectURL(blob)

    let image = new Image()
    image.onload = () => {
      let canvas = document.createElement('canvas')

      canvas.width = width

      canvas.height = height
      let context = canvas.getContext('2d')
      // draw image in canvas starting left-0 , top - 0
      context.drawImage(image, 0, 0, width, height)

      let png = canvas.toDataURL()

      res(png)
    }
    image.onerror = rej
    image.src = blobURL
  })
}

export const getSvgCodeFromFile = svgFilePath => {
  return new Promise((res, rej) => {
    fetch(svgFilePath)
      .then(result => res(result.text()))
      .catch(rej)
  })
}

export const getFileTypeHead = url => {
  return new Promise((res, rej) => {
    var xhttp = new XMLHttpRequest()
    xhttp.open('HEAD', url, true)

    // xhttp.setRequestHeader('Access-Control-Allow-Origin', '*')
    // xhttp.setRequestHeader('Access-Control-Allow-Credentials', 'true')
    // xhttp.setRequestHeader(
    //   'Access-Control-Allow-Methods',
    //   'GET,HEAD,OPTIONS,POST,PUT'
    // )
    // xhttp.setRequestHeader(
    //   'Access-Control-Allow-Headers',
    //   'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    // )
    xhttp.onreadystatechange = function () {
      if (this.readyState == this.DONE) {
        res(this.getResponseHeader('Content-Type'))
      } else {
        rej(this ? this.statusText : 'Network error')
      }
    }
    xhttp.onerror = function () {
      rej(this ? this.statusText : 'Network error')
    }
    xhttp.addEventListener('error', () => {
      rej(this ? this.statusText : 'Network error')
    })
    xhttp.send()
  })
}

export const getFileType = url => {
  return new Promise((res, rej) => {
    var xhttp = new XMLHttpRequest()
    xhttp.open('GET', url, true)

    // xhttp.setRequestHeader('Access-Control-Allow-Origin', '*')
    // xhttp.setRequestHeader('Access-Control-Allow-Credentials', 'true')
    // xhttp.setRequestHeader(
    //   'Access-Control-Allow-Methods',
    //   'GET,HEAD,OPTIONS,POST,PUT'
    // )
    // xhttp.setRequestHeader(
    //   'Access-Control-Allow-Headers',
    //   'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    // )
    xhttp.onreadystatechange = function () {
      var contentType = xhttp.getResponseHeader('Content-Type')
      if (contentType) {
        // Stop downloading, the headers are all we need.
        xhttp.abort()
        res(contentType)
      }
    }
    xhttp.onerror = function () {
      rej(this.statusText)
    }
    xhttp.addEventListener('error', () => {
      rej(this.statusText)
    })
    xhttp.send()
  })
}

export const replaceTokenIdIfNeeded = (web3Utils, url, tokenId) => {
  if (url.indexOf('{id}') !== -1) {
    const hexNumber = web3Utils.padLeft(web3Utils.numberToHex(tokenId), 64)
    url = url.replace('{id}', hexNumber.slice(2))
  }
  return url
}

export const replaceIpfsIfNeeded = url => {
  if (url.indexOf('ipfs://ipfs/') !== -1) {
    url = url.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/')
  } else if (url.indexOf('ipfs://') !== -1) {
    url = url.replace('ipfs://', 'https://ipfs.io/ipfs/')
  } else if (url.startsWith('/ipfs/')) {
    url = 'https://ipfs.io/ipfs/' + url.substring(6)
  } else if (url.startsWith('Qm')) {
    url = 'https://ipfs.io/ipfs/' + url
  } else if (url.startsWith('ar://')) {
    url = url.replace('ar://', 'https://arweave.net/')
  }
  return url
}

export const randomHex = len => {
  return (
    '0x' +
    [...crypto.getRandomValues(new Uint8Array(len))]
      .map(m => ('0' + m.toString(16)).slice(-2))
      .join('')
  )
}

export const getDefaultExternalTokenUrlForChain = (
  contractAddress,
  tokenId,
  chainId
) => {
  return chainId === 1
    ? `https://opensea.io/assets/${contractAddress}/${tokenId}`
    : chainId === 137
    ? `https://opensea.io/assets/matic/${contractAddress}/${tokenId}`
    : chainId === 999
    ? `https://www.magiceden.io/item-details/${contractAddress}`
    : ''
}
