const getCornerSvg = () => {
  return ` <svg>
  <style>
    .st233{fill:#fff}.st8183{filter:url(#AI_GaussianBlur_4)}.st8192{filter:url(#AI_GaussianBlur_1)}.st8193{fill:none;stroke:#fff;stroke-miterlimit:10}
  </style>
  <filter id="AI_GaussianBlur_7">
    <feGaussianBlur stdDeviation="7"/>
  </filter>
  <filter id="AI_GaussianBlur_4">
    <feGaussianBlur stdDeviation="4"/>
  </filter>
  <filter id="AI_GaussianBlur_1">
    <feGaussianBlur stdDeviation="1"/>
  </filter>
  <g id="Layer_7">
    <path d="M176.11 30.21 146.22.33h-34.85C105.9-.1 100.4-.1 94.93.33H.33v94.61c-.43 5.47-.43 10.97 0 16.44v34.85l29.88 29.88c40.29 40.29 105.61 40.29 145.9 0 40.28-40.29 40.28-105.61 0-145.9z" class="st8183"/>
    <path d="M176.11 30.21 146.22.33h-34.85C105.9-.1 100.4-.1 94.93.33H.33v94.61c-.43 5.47-.43 10.97 0 16.44v34.85l29.88 29.88c40.29 40.29 105.61 40.29 145.9 0 40.28-40.29 40.28-105.61 0-145.9z"/>
    <path d="M103.16 191.09c-22.53 0-45.06-8.58-62.21-25.73-34.3-34.3-34.3-90.11 0-124.42 34.3-34.3 90.11-34.3 124.42 0 34.3 34.3 34.3 90.11 0 124.42-17.15 17.16-39.68 25.73-62.21 25.73zm0-172.87c-21.76 0-43.52 8.28-60.09 24.85-33.13 33.13-33.13 87.04 0 120.17s87.04 33.13 120.17 0 33.13-87.04 0-120.17c-16.56-16.56-38.32-24.85-60.08-24.85z" class="prefix_main_colour"/>
    <circle cx="103.16" cy="103.16" r="76.75" class="prefix_main_colour"/>
    <circle cx="103.16" cy="103.16" r="76.75" class="prefix_main_colour"/>
    <defs>
      <circle id="SVGID_00000035510155080875339320000005840746774255397299_" cx="103.16" cy="103.16" r="76.75"/>
    </defs>
    <clipPath id="SVGID_00000091703805299141336250000007496257268933221270_">
      <use xlink:href="#SVGID_00000035510155080875339320000005840746774255397299_" style="overflow:visible"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000091703805299141336250000007496257268933221270_)">
      <linearGradient id="SVGID_00000168821105303411967810000007903465957404515713_" x1="62.6526" x2="12.0662" y1="24.9997" y2="-22.1729" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
        <stop offset=".1005" style="stop-color:#fff;stop-opacity:0"/>
        <stop offset=".5" style="stop-color:#fff"/>
        <stop offset=".9206" style="stop-color:#fff;stop-opacity:0"/>
      </linearGradient>
      <path d="m103.16 102.58-86.37 44.47 51.49 46.2z" style="opacity:.4;fill:url(#SVGID_00000168821105303411967810000007903465957404515713_)"/>
      <linearGradient id="SVGID_00000083811234774459657420000015205799208361920168_" x1="2.8507" x2="-47.7357" y1="94.2447" y2="47.0721" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
        <stop offset=".1005" style="stop-color:#fff;stop-opacity:0"/>
        <stop offset=".5" style="stop-color:#fff"/>
        <stop offset=".9206" style="stop-color:#fff;stop-opacity:0"/>
      </linearGradient>
      <path d="m103.16 102.58 86.37-44.47-51.49-46.2z" style="opacity:.4;fill:url(#SVGID_00000083811234774459657420000015205799208361920168_)"/>
    </g>
    <radialGradient id="SVGID_00000128446421080893183100000005057185458992922011_" cx="7.4584" cy="35.4584" r="33.3904" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#b4b3b3"/>
      <stop offset=".1295" style="stop-color:#807f7f"/>
      <stop offset=".2617" style="stop-color:#535252"/>
      <stop offset=".3886" style="stop-color:#2f2e2e"/>
      <stop offset=".507" style="stop-color:#151515"/>
      <stop offset=".6137" style="stop-color:#050505"/>
      <stop offset=".6984" style="stop-color:#000"/>
    </radialGradient>
    <circle cx="103.16" cy="103.16" r="33.39" style="fill:url(#SVGID_00000128446421080893183100000005057185458992922011_)"/>
    <circle cx="88.94" cy="113.16" r="7.15" class="st233"/>
    <g class="st8183">
      <path d="M103.16 191.09c-22.53 0-45.06-8.58-62.21-25.73-34.3-34.3-34.3-90.11 0-124.42 34.3-34.3 90.11-34.3 124.42 0 34.3 34.3 34.3 90.11 0 124.42-17.15 17.16-39.68 25.73-62.21 25.73zm0-172.87c-21.76 0-43.52 8.28-60.09 24.85-33.13 33.13-33.13 87.04 0 120.17s87.04 33.13 120.17 0 33.13-87.04 0-120.17c-16.56-16.56-38.32-24.85-60.08-24.85z" class="prefix_main_colour"/>
    </g>
    <g class="st8183">
      <path d="M23.43 42.8c-4.96 0-9.93-1.89-13.7-5.67-7.56-7.56-7.56-19.85 0-27.41 7.56-7.56 19.85-7.56 27.41 0 7.56 7.56 7.56 19.85 0 27.41-3.79 3.78-8.75 5.67-13.71 5.67zm0-35.74c-4.19 0-8.39 1.6-11.58 4.79-6.39 6.39-6.39 16.78 0 23.16 6.39 6.39 16.78 6.39 23.16 0 6.39-6.39 6.38-16.78 0-23.16-3.19-3.2-7.39-4.79-11.58-4.79z" class="prefix_main_colour"/>
    </g>
    <path d="M23.43 42.8c-4.96 0-9.93-1.89-13.7-5.67-7.56-7.56-7.56-19.85 0-27.41 7.56-7.56 19.85-7.56 27.41 0 7.56 7.56 7.56 19.85 0 27.41-3.79 3.78-8.75 5.67-13.71 5.67zm0-35.74c-4.19 0-8.39 1.6-11.58 4.79-6.39 6.39-6.39 16.78 0 23.16 6.39 6.39 16.78 6.39 23.16 0 6.39-6.39 6.38-16.78 0-23.16-3.19-3.2-7.39-4.79-11.58-4.79z" class="prefix_main_colour"/>
    <circle cx="23.43" cy="23.43" r="11.15" class="prefix_main_colour"/>
    <defs>
      <circle id="SVGID_00000036943876569535214140000018404562236909909657_" cx="23.43" cy="23.43" r="11.15"/>
    </defs>
    <clipPath id="SVGID_00000089540750756458315860000001535152499674994082_">
      <use xlink:href="#SVGID_00000036943876569535214140000018404562236909909657_" style="overflow:visible"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000089540750756458315860000001535152499674994082_)">
      <linearGradient id="SVGID_00000054236159753501633750000000259354954363403155_" x1="95.2061" x2="87.8584" y1="113.6699" y2="106.8181" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
        <stop offset=".1005" style="stop-color:#fff;stop-opacity:0"/>
        <stop offset=".5" style="stop-color:#fff"/>
        <stop offset=".9206" style="stop-color:#fff;stop-opacity:0"/>
      </linearGradient>
      <path d="M23.43 23.34 10.88 29.8l7.48 6.71z" style="opacity:.4;fill:url(#SVGID_00000054236159753501633750000000259354954363403155_)"/>
      <linearGradient id="SVGID_00000065062770646784775950000016469530877078701727_" x1="86.5198" x2="79.1721" y1="123.7278" y2="116.8759" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
        <stop offset=".1005" style="stop-color:#fff;stop-opacity:0"/>
        <stop offset=".5" style="stop-color:#fff"/>
        <stop offset=".9206" style="stop-color:#fff;stop-opacity:0"/>
      </linearGradient>
      <path d="m23.43 23.34 12.54-6.46-7.48-6.71z" style="opacity:.4;fill:url(#SVGID_00000065062770646784775950000016469530877078701727_)"/>
    </g>
    <radialGradient id="SVGID_00000088133249438047362170000018208019196515933574_" cx="87.1891" cy="115.189" r="4.85" gradientTransform="rotate(180 55.3082 69.3082)" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#b4b3b3"/>
      <stop offset=".1295" style="stop-color:#807f7f"/>
      <stop offset=".2617" style="stop-color:#535252"/>
      <stop offset=".3886" style="stop-color:#2f2e2e"/>
      <stop offset=".507" style="stop-color:#151515"/>
      <stop offset=".6137" style="stop-color:#050505"/>
      <stop offset=".6984" style="stop-color:#000"/>
    </radialGradient>
    <circle cx="23.43" cy="23.43" r="4.85" style="fill:url(#SVGID_00000088133249438047362170000018208019196515933574_)"/>
    <circle cx="21.36" cy="24.88" r="1.04" class="st233"/>
    <g class="st8192">
      <circle cx="103.16" cy="103.16" r="86.47" class="st8193"/>
    </g>
    <g class="st8192">
      <circle cx="23.43" cy="23.43" r="17.88" class="st8193"/>
    </g>
  </g>
</svg> `
}

export default getCornerSvg