import React from 'react'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Container from './Container'

const MintNotAllowed = ({ show }) => {
  if (!show) return null
  return (
    <Container>
      <Alert severity='error' sx={{ width: '100%' }}>
        <AlertTitle>
          Your account is not in the whitelist for the presale
        </AlertTitle>
      </Alert>
    </Container>
  )
}

export default MintNotAllowed
