import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import Container from '../common/Minting/Forms/Container'
import Background from '../common/Minting/Forms/Background'
import { shortenAddress } from '../../lib/web3/utils'
import { useMint } from '../../hooks/useMint'
import { useCheckMintStage } from '../../hooks/useCheckMintStage'
import { SupportedChain } from '../../lib/constants'
import { openInNewTab } from '../../lib/utils'
import useMediaQuery from '@mui/material/useMediaQuery'

const EXPLORER_URLS = {
  [SupportedChain.Ethereum]: 'https://etherscan.io',
  [SupportedChain.Ropsten]: 'https://ropsten.etherscan.io',
  [SupportedChain.Rinkeby]: 'https://rinkeby.etherscan.io',
  [SupportedChain.Goerli]: 'https://goerli.etherscan.io'
}

const MintingForm = ({ limit = 4, openAddressModal, amount, setAmount }) => {
  const { account } = useWeb3React()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { execute: onMint, isLoading, value, error } = useMint(
    amount,
    account,
    false
  )
  const { isLoading: loadingStage, value: mintStage } = useCheckMintStage(
    account
  )

  const [showForm, setShowForm] = useState(true)

  useEffect(() => {
    if (error || value) setShowForm(false)
  }, [error, value])

  if (loadingStage) return null
  if (mintStage !== '2') return <span>Minting is not allowed yet!</span>

  const menuItems = Array.from({ length: limit }, (_, i) => (
    <MenuItem value={i + 1}>{i + 1}</MenuItem>
  ))
  return (
    <Container glow={!isMobile}>
      <Success
        result={value}
        showForm={showForm}
        onOk={() => setShowForm(true)}
      />
      <Error
        error={error}
        showForm={showForm}
        onRetry={() => setShowForm(true)}
      />
      {showForm && (
        <>
          <Background>
            <Typography mb={1} align='center'>
              You are connected with
            </Typography>
            <Button
              disabled={isLoading}
              onClick={openAddressModal}
              variant='outlined'
            >
              {shortenAddress(account)}
            </Button>
            <FormControl disabled={isLoading} sx={{ mt: 6 }}>
              <InputLabel id='mint-amount-label'>Mint Amount</InputLabel>
              <Select
                labelId='mint-amount-label'
                id='select-mint-amount'
                value={amount}
                label='Mint Amount'
                onChange={event => setAmount(event.target.value)}
              >
                {menuItems}
              </Select>
              <FormHelperText>
                You are limited to {limit} per transaction!
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ my: 2 }}>
              <LoadingButton
                loading={isLoading}
                onClick={onMint}
                variant='contained'
              >
                Mint
              </LoadingButton>
            </FormControl>
          </Background>
        </>
      )}
    </Container>
  )
}

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-left: ${({ theme }) => theme.spacing(4)};
//   margin-right: ${({ theme }) => theme.spacing(4)};
// `

const Success = ({ result, showForm, onOk }) => {
  const { chainId } = useWeb3React()

  if (!result || showForm) return null

  return (
    <Alert
      severity='success'
      action={
        <Button onClick={onOk} color='inherit' size='small'>
          OK
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>You successfully minted your Frames!</AlertTitle>
      <Button
        aria-label='view-etherscan'
        size='small'
        color='inherit'
        endIcon={<OpenInNewOutlinedIcon fontSize='small' />}
        onClick={() =>
          openInNewTab(`${EXPLORER_URLS[chainId]}/tx/${result.transactionHash}`)
        }
      >
        View tx on Etherscan
      </Button>
    </Alert>
  )
}

const Error = ({ error, showForm, onRetry }) => {
  if (!error || showForm) return null

  const stackTrace =
    error.message &&
    error.message.includes('Transaction has been reverted by the EVM')
      ? error.message
      : null
  const errorMessage = stackTrace ? 'Something went wrong!' : error.message
  return (
    <Alert
      severity='error'
      action={
        <Button onClick={onRetry} color='inherit' size='small'>
          RETRY
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>{errorMessage}</AlertTitle>
      {stackTrace && (
        <TextareaAutosize
          aria-label='error stacktrace'
          minRows={3}
          maxRows={10}
          style={{ width: '100%' }}
        >
          {stackTrace}
        </TextareaAutosize>
      )}
    </Alert>
  )
}

export default MintingForm
