import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, { useState } from 'react'
import { checkOwnsSolanaToken } from '../../../usecase/CheckOwnsSolanaTokenUseCase'
import { connectSolanaWallet } from '../../../usecase/ConnectSolanaWalletUsecase'
import { isValidAddress } from '../../../usecase/IsValidAddressForChainUsecase'
import { getAndProcessTokenMetadata } from '../../../usecase/GetAndProcessTokenMetadataUseCase'
import { CHAIN_INFO_BY_NAME } from '../../../lib/constants'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import { green } from '@mui/material/colors'
import { Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'

export default function SolanaTokenInput (props) {
  const [tokenAddress, setTokenAddress] = useState(null)
  const [tokenAddressError, setTokenAddressError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const checkNft = async () => {
    setErrorMessage('')
    setLoading(true)

    try {
      const walletInfo = await connectSolanaWallet()
      console.log('wallet info', walletInfo)
      const metadata = await getAndProcessTokenMetadata(
        null,
        tokenAddress,
        tokenAddress,
        CHAIN_INFO_BY_NAME.Solana.id
      )
      const ownsToken = await checkOwnsSolanaToken(
        tokenAddress,
        walletInfo.address
      )
      console.log('solana metadata', metadata)
      console.log('Owns this token?', ownsToken)
      if (!ownsToken) {
        setTokenAddressError(true)
        setErrorMessage(`You don't own any of these tokens`)
      } else {
        props.onTokenSelected &&
          props.onTokenSelected(
            tokenAddress,
            tokenAddress,
            {
              contract: {
                id: tokenAddress,
                name: metadata.name,
                symbol: metadata.symbol,
                type: CHAIN_INFO_BY_NAME.Solana.id
              },
              tokenID: 0
            },
            metadata.metadata,
            props.chainId
          )
      }
    } catch (error) {
      console.error(error)
      let message
      if (
        error.message.indexOf(
          'you are not using the correct ABI for the contract'
        ) !== -1
      ) {
        setTokenAddressError(true)
        message = 'Contract is not supported'
      } else if (
        error.message.indexOf(`You don't own any of these tokens`) !== -1
      ) {
        setTokenAddressError(true)
        message = error.message
      } else {
        setTokenAddressError(true)
        message = error.message
      }
      setErrorMessage(message)
    }

    setLoading(false)
  }

  return (
    <Paper
      component='form'
      sx={{
        p: '12px 0px',
        ...(props.large && { p: '24px 0px' }),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...(props.sx
          ? {
              ...props.sx
            }
          : {})
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: props.large ? 'column' : 'row',
          flex: 1,
          width: '100%',
          alignItems: 'center'
        }}
      >
        {props.large && (
          <Typography
            variant={'h4'}
            component='p'
            align='left'
            color={'inherit'}
            sx={{
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 300,
              textShadow: '2px 2px 5px black'
            }}
          >
            Token Details
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            ...(props.large && { m: '24px 12px' }),
            flexDirection: props.large ? 'column' : 'row',
            width: '90%',
            alignItems: 'center'
          }}
        >
          <TextField
            label='Token Address'
            variant='standard'
            sx={{
              ...(!props.large && { ml: '12px' }),
              ...(props.large && { width: '100%', pb: '24px' }),
              flex: 1
            }}
            error={tokenAddressError}
            placeholder='Token Address'
            inputProps={{
              'aria-label': 'Token address',
              pattern: '^0x[a-fA-F0-9]{40}$'
            }}
            onChange={event => {
              const valid = isValidAddress(event.target.value, props.chainId)

              setTokenAddressError(event.target.value ? !valid : false)
              setErrorMessage(
                event.target.value
                  ? !valid
                    ? 'Enter Valid Token Address'
                    : ''
                  : ''
              )
              setTokenAddress(event.target.value)
            }}
          />
        </Box>
        {!props.large && (
          <Divider sx={{ height: '100%', m: 1 }} orientation='vertical' />
        )}

        <Box sx={{ mr: 1, position: 'relative' }}>
          {!props.large ? (
            <>
              <Fab
                aria-label='save'
                color='primary'
                disabled={loading || tokenAddressError || !tokenAddress}
                onClick={checkNft}
              >
                <KeyboardArrowRightIcon />
              </Fab>
              {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: -6,
                    left: -6,
                    zIndex: 1
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Button
                variant='contained'
                disabled={loading || tokenAddressError || !tokenAddress}
                onClick={checkNft}
              >
                Use Token For frame contents
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <Collapse in={errorMessage}>
        <Typography
          variant={'subtitle2'}
          component='p'
          align='center'
          color={'error'}
          sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 300,
            mt: 1,
            textShadow: '2px 2px 5px black'
          }}
        >
          {errorMessage}
        </Typography>
      </Collapse>
    </Paper>
  )
}
