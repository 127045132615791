import React from 'react'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { Button } from '@mui/material'

const CloseButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const CloseButton = ({ show, onClick } = {}) => {
  if (!show) return null
  return (
    <CloseButtonWrapper onClick={onClick}>
      <ClearIcon />
    </CloseButtonWrapper>
  )
}

const Notifications = () => {
  return (
    <Toaster position='bottom-center' reverseOrder={false}>
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              <CloseButton
                show={t.type !== 'loading'}
                onClick={() => toast.dismiss(t.id)}
              />
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default Notifications
