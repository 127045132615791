import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'

const IconLink = ({ to, text, icon, target = '_self', hideIcon, className }) => {
  return (
    <StyledLink to={to} target={target} className={className}>
      <Title variant='body1'>{text}</Title>
      {!hideIcon && (icon || <OpenInNewOutlinedIcon fontSize='inherit' />)}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  display: flex;
  align-items: center;
`

const Title = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export default IconLink
