import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UnsupportedChainIdError } from '@web3-react/core'
import Container from '../components/common/PageContainer'
import ConnectWalletModal from '../components/common/wallet/modal'
import Error from '../components/common/buttons/ErrorConnectWalletButton'
import ConnectWalletButton from '../components/common/buttons/ConnectWalletButton'
import { useGetFrameTokenMetadata } from '../hooks/useGetFrameTokenMetadata'
import { useActiveWeb3React } from '../hooks/web3'
import { getAndProcessTokenMetadata } from '../usecase/GetAndProcessTokenMetadataUseCase'
import LoadingAnimation from '../components/common/loading/LoadingAnimation'
import FramePageContent from '../components/common/frame/FramePageContent'
import { switchToEthereum } from '../usecase/SwitchToEthereumUsecase'

const FramePage = () => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <Container>
      <Content open={() => setOpenModal(true)} />
      <ConnectWalletModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </Container>
  )
}

const Content = ({ open }) => {
  const { library, error, account } = useActiveWeb3React()

  if (error)
    return (
      <Error
        onClick={() => {
          open()
          switchToEthereum(account)
        }}
        text={
          error instanceof UnsupportedChainIdError
            ? 'Unsupported Network'
            : 'Error'
        }
      />
    )

  if (library) {
    return <FrameContainer />
  }

  return <ConnectWalletButton onClick={open} text='Connect Your Wallet' />
}

const FrameContainer = () => {
  let { id } = useParams()
  const { library } = useActiveWeb3React()

  const [errorLoading, setErrorLoading] = useState(null)
  const [loadingMetadata, setLoadingMetadata] = useState(true)
  const [frameMetadata, setFrameMetadata] = useState(null)

  const {
    execute: executeMetadata,
    isLoading: isLoadingFrame,
    value: frameData,
    error: errorData
  } = useGetFrameTokenMetadata(id, true)

  useEffect(() => {
    async function onLoad () {
      if (isLoadingFrame) return
      if (errorData) {
        console.error(errorData)
        setErrorLoading(
          errorData.message.indexOf('Invalid Token ID') !== -1
            ? `Token #${id} does not exist!\nPlease use an existing token id`
            : errorData.message
        )
        setLoadingMetadata(false)
        return
      }
      if (!frameData) return
      setLoadingMetadata(true)
      setErrorLoading(null)
      try {
        const theMetadata = await getAndProcessTokenMetadata(
          library,
          frameData.contract.id,
          frameData.tokenID
        )
        setErrorLoading(null)
        setFrameMetadata(theMetadata.metadata)
      } catch (e) {
        setErrorLoading(e.message)
      }

      setLoadingMetadata(false)
    }

    onLoad()

    return () => {}
  }, [library, isLoadingFrame, frameData])

  useEffect(() => {
    async function onLoad () {
      await executeMetadata()
    }

    onLoad()

    return () => {}
  }, [id])

  return isLoadingFrame || loadingMetadata ? (
    <LoadingAnimation />
  ) : errorLoading ? (
    <Error text={errorLoading} />
  ) : (
    <FramePageContent frame={frameData} frameMetadata={frameMetadata} />
  )
}

export default FramePage
