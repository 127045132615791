import React from 'react'
import { useWeb3React } from '@web3-react/core'
import SUPPORTED_WALLETS from '../../../../lib/connectors/supportedWallets'
import { injected } from '../../../../lib/connectors'
import WalletOption from './WalletOption'
import { VIEWS } from './Content'

const WalletList = ({ setView, activate }) => {
  const { connector } = useWeb3React()

  const injectedIsMetamask = window.ethereum && window.ethereum.isMetaMask
  return Object.keys(SUPPORTED_WALLETS).map((key) => {
    const wallet = SUPPORTED_WALLETS[key]

    // overwrite injected when needed
    if (wallet.connector === injected) {
      // don't show injected if there's no injected provider
      if (!(window.web3 || window.ethereum)) {
        if (wallet.name !== 'MetaMask') return null // only offer to install MetaMask
        return (
          <WalletOption
            id={`connect-${key}`}
            key={key}
            color={'#E8831D'}
            title='Install Metamask'
            subheader={null}
            link={'https://metamask.io/'}
            icon={SUPPORTED_WALLETS.METAMASK.icon}
          />
        )
      } else if (wallet.name === 'MetaMask' && !injectedIsMetamask) return null // don't return metamask if injected provider isn't metamask
      else if (wallet.name === 'Injected' && injectedIsMetamask) return null // likewise for generic
    }

    return (
      <WalletOption
        id={`connect-${key}`}
        onClick={() => {
          wallet.connector === connector
            ? setView(VIEWS.ACCOUNT)
            : !wallet.href && activate(wallet.connector)
        }}
        key={key}
        active={wallet.connector === connector}
        color={wallet.color}
        link={wallet.href}
        title={wallet.name}
        subheader={null}
        icon={wallet.icon}
      />
    )
  })
}

export default WalletList
