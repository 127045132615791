import React from 'react'
import { IconButton, Icon } from '@mui/material'
import { openInNewTab } from '../../../lib/utils'

export default function SocialLink ({ logo, altText, href }) {
  return (
    <IconButton
      sx={{ borderRadius: 0 }}
      color='inherit'
      aria-label={altText}
      component='span'
      onClick={() => openInNewTab(href)}
    >
      <Icon>
        <img alt={altText} src={logo} />
      </Icon>
    </IconButton>
  )
}
