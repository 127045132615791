import React from 'react'
import styled from 'styled-components'
import { Icon, Button } from '@mui/material'

import OpenSeaLogo from '../../../assets/images/logos/opensea_logo_white.svg'

const OpenseaButton = () => {
  return (
    <StyledButton
      variant='contained'
      disableElevation
      fullWidth
      align='center'
      endIcon={<OpenseaIcon />}
      component='a'
      target='_blank'
      href='https://opensea.io/collection/frames-by-murall'
    >
      View on Opensea
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  background-color: #2081e2;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

const OpenseaIcon = () => (
  <Icon>
    <img alt='opensea' src={OpenSeaLogo} />
  </Icon>
)

export default OpenseaButton
