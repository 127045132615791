import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote'

const PresaleButton = () => {
  return ( 
    <StyledButton
      variant='contained'
      color='secondary'
      disableElevation
      fullWidth
      align='center'
      endIcon={<EventNoteIcon />}
      component='a'
      target='_blank'
      href='https://forms.gle/taVJCS9Qr54jzPDm6'
    >
      Presale Registration
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export default PresaleButton
