import { useState, useEffect, useCallback } from 'react'
import { useMurAllFrameContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
import { FramesContractDetails } from '../lib/constants'
export const useGetFrameTokenMetadataForAccountInRange = (
  startIndex,
  limit,
  account,
  immediate = true
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const { library } = useWeb3React()
  const murallFrameContract = useMurAllFrameContract()

  const getNftInfoForIndex = index => {
    //a function that returns a promise
    return new Promise(async function (resolve, reject) {
      const tokenId = await murallFrameContract.methods
        .tokenOfOwnerByIndex(account, index)
        .call()
      let traitHash
      try {
        traitHash = await murallFrameContract.methods.getTraits(tokenId).call()
        traitHash = library.utils.padLeft(
          library.utils.numberToHex(traitHash),
          64
        )
      } catch (e) {
        console.error(e)
        reject(e)
      }

      const returnNft = {
        contract: FramesContractDetails,
        tokenID: tokenId,
        traitHash: traitHash
      }
      resolve(returnNft)
    })
  }

  const execute = useCallback(async () => {
    if (!murallFrameContract) return
    setIsLoading(true)
    setValue(null)
    setError(null)
    const balance = await murallFrameContract.methods.balanceOf(account).call()
    let result
    if (startIndex > balance - 1) {
      result = []
      setValue(result)
      setIsLoading(false)
      return result
    }
    const endIndex =
      startIndex + (limit - 1) > balance - 1
        ? balance - 1
        : startIndex + (limit - 1)

    try {
      const indexes = [...Array(parseInt(endIndex + 1)).keys()].slice(
        startIndex,
        endIndex + 1
      )
      result = await Promise.all(
        indexes.map(index => getNftInfoForIndex(index))
      )

      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
    return result
  }, [murallFrameContract, startIndex, limit, account])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useGetFrameTokenMetadataForAccountInRange
