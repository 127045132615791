import { combineReducers } from 'redux'

import { GraphApi } from './graphql'
import { Api } from './api'
import { OpenseaApi } from './opensea'
const rootReducer = combineReducers({
  [GraphApi.reducerPath]: GraphApi.reducer,
  [Api.reducerPath]: Api.reducer,
  [OpenseaApi.reducerPath]: OpenseaApi.reducer
})

export default rootReducer
