import { useState, useEffect, useCallback } from 'react'
import { useMurAllFrameContract, useMintManagerContract } from './useContract'
import { BigNumber } from 'bignumber.js'

export const useMintPresale = (
  amount,
  account,
  index,
  merkleProof,
  immediate = true
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  const murallFrameContract = useMurAllFrameContract()
  const mintManagerContract = useMintManagerContract()

  const execute = useCallback(async () => {
    if (!murallFrameContract || !merkleProof) return

    setIsLoading(true)
    setValue(null)
    setError(null)

    try {
      const presalePrice = await mintManagerContract.methods
        .mintPricePresale()
        .call()

      const mintCost = new BigNumber(presalePrice).times(amount)

      const result = await murallFrameContract.methods
        .mintPresale(index, 2, merkleProof, amount)
        .send({ from: account, value: mintCost })
      setValue(result)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [murallFrameContract, amount, account, index, merkleProof])

  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, isLoading, value, error }
}

export default useMintPresale
