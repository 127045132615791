import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import useMediaQuery from '@mui/material/useMediaQuery'

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Success from './Success'
import Error from './Error'
import NotAllowed from './NotAllowed'
import Container from './Container'
import Background from './Background'

import { shortenAddress } from '../../../../lib/web3/utils'
import { useMintPresale } from '../../../../hooks/useMintPresale'
import { useCheckMintStage } from '../../../../hooks/useCheckMintStage'

const PresaleMintingForm = ({
  limit = 2,
  openAddressModal,
  amount,
  setAmount
}) => {
  const [showForm, setShowForm] = useState(true)
  const [proofData, setProofData] = useState(null)
  const { account } = useWeb3React()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { isLoading: loadingStage, value: mintStage } = useCheckMintStage(
    account
  )

  const { index, proof } = proofData || {}
  const { execute: onMint, isLoading, value, error } = useMintPresale(
    amount,
    account,
    index,
    proof,
    false
  )

  useEffect(() => {
    if (!account) return
    const fetchProof = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/presale/claims/${account}.json`
        )
        const jsonResponse = await response.json()
        setProofData({
          index: jsonResponse.index,
          amount: parseInt(jsonResponse.amount, 16),
          proof: jsonResponse.proof
        })
      } catch (error) {
        console.error(error)
        setProofData(null)
      }
    }
    fetchProof()
  }, [account])

  useEffect(() => {
    if (error || value) setShowForm(false)
  }, [error, value])

  if (loadingStage) return null
  if (mintStage !== '1') return <span>Presale minting is not allowed yet!</span>

  if (!proofData) return <NotAllowed show={true} />

  const menuItems = Array.from({ length: proofData.amount }, (_, i) => (
    <MenuItem value={i + 1}>{i + 1}</MenuItem>
  ))
  return (
    <Container glow={!isMobile}>
      <Success
        result={value}
        showForm={showForm}
        onOk={() => setShowForm(true)}
      />
      <Error
        error={error}
        showForm={showForm}
        onRetry={() => setShowForm(true)}
      />
      {showForm && (
        <Background>
          <Typography mb={10} mt={5} variant='h5' align='center'>
            Welcome to the Frames presale!
          </Typography>
          <Typography mb={1} align='center'>
            You are connected with
          </Typography>
          <Button
            disabled={isLoading}
            onClick={openAddressModal}
            variant='outlined'
          >
            {shortenAddress(account)}
          </Button>

          <FormControl disabled={isLoading} sx={{ mt: 6, minWidth: 250 }}>
            <InputLabel id='mint-amount-label'>Mint Amount</InputLabel>
            <Select
              labelId='mint-amount-label'
              id='select-mint-amount'
              value={amount}
              label='Mint Amount'
              onChange={event => setAmount(event.target.value)}
            >
              {menuItems}
            </Select>
            <FormHelperText sx={{ alignSelf: 'center', pt: 1 }}>
              NB! YOU CAN MINT ONLY ONCE!
            </FormHelperText>
          </FormControl>
          <FormControl sx={{ my: 2 }}>
            <LoadingButton
              loading={isLoading}
              onClick={onMint}
              variant='contained'
            >
              Mint
            </LoadingButton>
          </FormControl>
        </Background>
      )}
    </Container>
  )
}

export default PresaleMintingForm
