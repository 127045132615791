import { getIsErc721TransferApprovedFromAddress } from './GetIsErc721TransferApprovedFromAddressUsecase'
import { getIsErc1155TransferApprovedFromAddress } from './GetIsErc1155TransferApprovedFromAddressUsecase'
import { setErc721TransferApprovedFromAddress } from './SetErc721TransferApprovedFromAddressUsecase'
import { setErc1155TransferApprovedFromAddress } from './SetErc1155TransferApprovedFromAddressUsecase'

import { getIsContractErc1155 } from './GetIsContractErc1155Usecase'
import { getIsContractErc721 } from './GetIsContractErc721Usecase'

export const setTokenTransferApprovedIfNeeded = async (
  web3,
  contractAddress,
  account,
  spenderAddress
) => {
  return new Promise(async (res, rej) => {
    let isErc1155 = false
    try {
      isErc1155 = await getIsContractErc1155(web3, contractAddress)
    } catch (e) {
      console.error(e)
    }
    if (!isErc1155) {
      const isErc721 = await getIsContractErc721(web3, contractAddress)

      if (!isErc721) {
        throw new Error('Unsupported contract type - must be ERC115 or ERC721')
      }
      const allowed = await getIsErc721TransferApprovedFromAddress(
        web3,
        contractAddress,
        account,
        spenderAddress
      )
      if (!allowed) {
        try {
          await setErc721TransferApprovedFromAddress(
            web3,
            contractAddress,
            account,
            spenderAddress,
            true
          )
          res()
        } catch (error) {
          rej(error)
        }
      } else {
        res()
      }
    } else {
      const allowed = await getIsErc1155TransferApprovedFromAddress(
        web3,
        contractAddress,
        account,
        spenderAddress
      )
      if (!allowed) {
        try {
          await setErc1155TransferApprovedFromAddress(
            web3,
            contractAddress,
            account,
            spenderAddress,
            true
          )
          res()
        } catch (error) {
          rej(error)
        }
      } else {
        res()
      }
    }
  })
}
