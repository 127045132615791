import React from 'react'
import Carousel from '../components/common/Carousel'

export default function FramesCarousel (props) {
  const createImage = (imgPath, index) => (
    <img
      src={imgPath}
      alt=''
      style={{
        position: 'absolute',
        width: `100%`,
        height: '100%',
        objectFit: 'contain',
        padding: 0
      }}
    />
  )

  return (
    <Carousel
      {...props}
      // install Swiper modules
      spaceBetween={props.itemMargin}
      loop={true}
      centeredSlides={true}
      contents={props.images}
      onCreateContent={createImage}
    />
  )
}
