import React from 'react'
import styled from 'styled-components'

import SectionTitle from '../components/common/SectionTitle'
import InfoGrid from '../components/common/InfoGrid'
import WidthLimiter from '../components/common/WidthLimiter'

const NumbersSection = () => {
  return (
    <Container>
      <SectionTitle title='The Numbers' />
      <InfoGrid
        dividers
        contents={{
          'Max supply': 4444,
          'Max mintable per tx': 4,
          'Max mintable per address': 40,
          'Presale supply': 1008,
          Network: 'Ethereum',
          'Presale mint cost': '0.144 ETH + tx fee',
          'Public sale supply': 3000,
          'Public sale mint cost': '0.244 ETH + tx fee'
        }}
      />
    </Container>
  )
}

const Container = styled(WidthLimiter)`
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(12)};
  margin-top: ${({ theme }) => theme.spacing(6)};
`

export default NumbersSection
