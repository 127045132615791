import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { SUPPORTED_CHAINS } from '../constants'
import { NetworkProviderUrls, NetworkProviderHttpUrls } from './urls'

export const gnosisSafe = new SafeAppConnector()

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINS
})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAINS,
  rpc: NetworkProviderHttpUrls,
  qrcode: true
})

export const fallback = new NetworkConnector({
  urls: NetworkProviderHttpUrls,
  defaultChainId: 1
})
