import { connectSolanaWallet } from './ConnectSolanaWalletUsecase'
var nacl = require('tweetnacl')
nacl.util = require('tweetnacl-util')
export const signSolanaMessage = message => {
  return new Promise((resolve, reject) => {
    connectSolanaWallet()
      .then(({ publicKey }) => {
        console.log('Connected to solana')
        const encodedMessage = new TextEncoder().encode(message)
        return window.solana.signMessage(encodedMessage, 'utf8')
      })
      .then(signed => {
        resolve({
          account: window.solana.publicKey.toString(),
          message: message,
          signature: nacl.util.encodeBase64(signed.signature)
        })
      })
      .catch(error => {
        console.log('Error connecting to solana:', error)
        reject(error)
      })
  })
}
