import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { Typography } from '@mui/material'
import Fade from 'react-reveal/Fade'

const Item = ({ value, description }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Fade top>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{
            m: 'auto 0',
            marginLeft: 0,
            textShadow: '2px 2px 5px black',
            paddingLeft: '0px',
            maxWidth: '100px'
          }}
          align='right'
          variant='h4'
          color={theme => theme.palette.primary.contrastText}
        >
          {value}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot
            variant='filled'
            color='primary'
            sx={{
              width: 12,
              height: 12
            }}
          ></TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <Description isMobile={isMobile} text={description} />
      </TimelineItem>
    </Fade>
  )
}

const Description = ({ isMobile, text }) => {
  return (
    <TimelineContent
      sx={{ py: 3, px: 2, marginRight: 0, paddingRight: 0, maxWidth: '100vw' }}
    >
      <Typography
        variant={isMobile ? 'h6' : 'h5'}
        color={theme => theme.palette.primary.contrastText}
        sx={{ fontWeight: 400, textShadow: '2px 2px 5px black' }}
      >
        {text}
      </Typography>
    </TimelineContent>
  )
}

export default function StyledTimeline ({ items = [] }) {
  return (
    <Timeline>
      {items.map(content => (
        <Item value={content.timelineValue} description={content.description} />
      ))}
    </Timeline>
  )
}
