import getKintsugiCornerSvg from './kintsugi_corner'
import getKintsugiTileSvg from './kintsugi_tile'

import getOrnateCornerSvg from './ornate_corner'
import getOrnateTileSvg from './ornate_tile'
import getSpeakersCornerSvg from './speakers_corner'
import getSpeakersTileSvg from './speakers_tile'
import getCyberpunkHexCornerSvg from './cyberpunk_hex_corner'
import getCyberpunkHexTileSvg from './cyberpunk_hex_tile'

const STYLE_OPTIONS = {
  Kintsugi: {
    corner: getKintsugiCornerSvg,
    tile: getKintsugiTileSvg
  },
  'Cyberpunk Hexagon': {
    corner: getCyberpunkHexCornerSvg,
    tile: getCyberpunkHexTileSvg
  },
  Ornate: {
    corner: getOrnateCornerSvg,
    tile: getOrnateTileSvg
  },
  Speakers: {
    corner: getSpeakersCornerSvg,
    tile: getSpeakersTileSvg
  }
}

export default STYLE_OPTIONS
