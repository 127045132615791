import { setApprovedForAll } from '../datasource/Erc1155DataSource'

export const setErc1155TransferApprovedFromAddress = async (
  web3,
  tokenContractAddress,
  account,
  spenderAddress,
  approved
) => {
  return setApprovedForAll(
    web3,
    tokenContractAddress,
    account,
    spenderAddress,
    approved
  )
}
