import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import Modal from '@mui/material/Modal'
import Content from './Content'

import usePrevious from '../../../../hooks/usePrevious'

const WalletConnectModal = ({ isOpen, closeModal }) => {
  const { account } = useWeb3React()
  const previousAccount = usePrevious(account)

   // close on connection, when logged out before
   useEffect(() => {
    if (account && !previousAccount && isOpen) {
      closeModal()
    }
  }, [account, previousAccount, isOpen, closeModal])

  return (
    <Modal open={isOpen} onClose={closeModal} keepMounted>
      <Wrapper>
        <Content isModalOpen={isOpen} closeModal={closeModal} />
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-width: 250px;
  max-width: 420px;
  max-height: 90vh;
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};

  &:focus {
    outline: none;
  }
`

export default WalletConnectModal
