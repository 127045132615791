import React from 'react'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import TextareaAutosize from '@mui/material/TextareaAutosize'

const MintError = ({ error, showForm, onRetry }) => {
  if (!error || showForm) return null

  const stackTrace = (error.message && error.message.includes('Transaction has been reverted by the EVM')) ? error.message : null
  const errorMessage = stackTrace ? 'Something went wrong!' : error.message
  return (
    <Alert
      severity='error'
      action={
        <Button onClick={onRetry} color='inherit' size='small'>
          RETRY
        </Button>
      }
      sx={{ width: '100%' }}
    >
      <AlertTitle>{errorMessage}</AlertTitle>
      {stackTrace && (
        <TextareaAutosize
          aria-label='error stacktrace'
          minRows={3}
          maxRows={10}
          style={{ width: '100%' }}
        >{stackTrace}</TextareaAutosize>
      )}
    </Alert>
  )
}

export default MintError
