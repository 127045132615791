export const SVG_DEFS_TAG_START = `<defs>`
export const SVG_DEFS_TAG_END = `</defs>`
export const FRAME_RIM_HEIGHT = 125
export const FRAME_RIM_WIDTH = 250
export const CORNER_PIECE_HEIGHT = 250
export const CORNER_PIECE_ID = 'cornerdetail'
export const TILE_PIECE_ID = 'Pattern'
export const SVG_TAG_END = `</svg>`
export const IMAGE_HEIGHT = 1024
export const IMAGE_WIDTH = 1024
export const SIGNATURE_SIZE = 125
export const SIGNATURE_ID = 'signature'
export const ANIMATION_ID = 'effect'
export const PLAQUE_ID = 'plaque'
export const NUMBER_VARIANTS_FRAMES = 159
export const NUMBER_VARIANTS_CORNERS = 194
export const NUMBER_VARIANTS_RIMS = 15
export const NUMBER_VARIANTS_EFFECTS = 8
export const NUMBER_VARIANTS_ANIMATION = 7
export const NUMBER_VARIANTS_PLAQUES = 15
export const PLAQUE_HEIGHT = 125
export const PLAQUE_WIDTH = 280
export const SIGNATURE_HEIGHT = 100
export const SIGNATURE_WIDTH = 100
export const CHANCE_OF_RIMS = 128 // 100% = 255
export const CHANCE_OF_EFFECT = 64 // 100% = 255
export const CHANCE_OF_PLAQUE = 64 // 100% = 255
export const CHANCE_OF_ANIMATION = 44 // 100% = 255
export const CHANCE_OF_SCROLLING_FRAME = 44 // 100% = 255
export const ID_PREFIX_FRAMES = "FRAME_"
export const ID_PREFIX_CORNERS = "CORNER_"
export const ID_PREFIX_RIMS = "RIMS_"
export const ID_PREFIX_EFFECTS = "EFFECT_"
export const ID_PREFIX_ANIMATION = "ANIMATION_"
export const ID_PREFIX_PLAQUE = "PLAQUE_"
export const IPFS_FOLDER_FRAMES = "QmX6XwJzcN5bA66X4LKZELzvmYtevGrAaHR72E1vwJa6p9"
export const IPFS_FOLDER_CORNERS = "QmdzRhFH4ZN68ym7GPwaUihRDsHnBmM3Ma9aw5msEUcyvY"
export const IPFS_FOLDER_RIMS = "QmSigkU74cDqqR9hiTuMzLbv4JzRjENc9gn4FaKfksddfC"
export const IPFS_FOLDER_EFFECTS = "QmW5KWd8KcnNxWhWYPX18HG5oNGjeZ66SwgEc68A59u2hn"
export const IPFS_FOLDER_ANIMATIONS = "QmUCydZ1xCEMcK1fzZBGTFPHXqKg7MZ7bbW5oc62tyeqxE"
export const IPFS_FOLDER_PLAQUES = "QmYjRdnLD6KGiZE8rDKyTubm2vRF3eccVSGvwpAww3gYL5"
