import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import NavLink from '../../common/Navbar/NavLink'
import Badge from '../../common/Badge'

const DesktopNavbarLinks = () => {
  return (
    <ButtonGroup
      id='navButtons'
      sx={{
        flexGrow: 1,
        justifyContent: 'center'
      }}
      size='large'
      color='inherit'
      variant='text'
      aria-label='large primary button group'
    >
      <NavLink title='DETAILS' to='/#details' />
      {/* <NavLink title='MINT' to='/mint' /> */}
      <NavLink title='NUMBERS' to='/#numbers' />
      <NavLink title='ROADMAP' to='/#roadmap' />
      <NavLink title='FAQ' to='/#faq' />
      <NavLink title='MOUNT' to='/mount' />
      <Badge badgeContent='NEW' color='primary'>
        <NavLink title='PRESALE' to='/presalemint' />
        {/* <NavLink title='MINT' to='/mint' /> */}
      </Badge>
    </ButtonGroup>
  )
}

export default DesktopNavbarLinks
