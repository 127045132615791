import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'

const ConnectWalletButton = ({ onClick, text, className }) => {
  return ( 
    <StyledButton
      variant='contained'
      disableElevation
      align='center'
      onClick={onClick}
      className={className}
    >
      {text}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export default ConnectWalletButton
