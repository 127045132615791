import {
  getVideoDimensionsOf,
  replaceIpfsIfNeeded,
  getFileType,
  getImageDimensionsOf
} from '../lib/helpers.js'

const FRAMES_PLACEHOLDER_IPFS = 'QmNjQbBXKhUjdCV5JPVKXfERKzRL4a4ajsjQD49Eor9Gmd'

export const processTokenMetadata = async (
  metadata,
  fetchDimensions = false,
  preferAnimation = false
) => {
  let videoUrl
  let mimeType

  if (metadata) {
    if (preferAnimation) {
      videoUrl = replaceIpfsIfNeeded(
        metadata.animation_url
          ? metadata.animation_url
          : metadata.image
          ? metadata.image
          : metadata.image_data
          ? metadata.image_data
          : metadata.image_url
      )
    } else {
      videoUrl = replaceIpfsIfNeeded(
        metadata.image
          ? metadata.image
          : metadata.image_data
          ? metadata.image_data
          : metadata.image_url
          ? metadata.image_url
          : metadata.animation_url
      )
    }

    if (videoUrl.indexOf('data:image') > -1) {
      mimeType = 'image/'
    } else {
      try {
        mimeType = await getFileType(videoUrl)
      } catch (e) {
        console.error('failed to get mimetype', e)
        if (videoUrl.indexOf('.mp4') !== -1) {
          mimeType = 'video/mp4'
        } else if (videoUrl.indexOf('.gif') !== -1) {
          mimeType = 'image/gif'
        } else if (videoUrl.indexOf('.png') !== -1) {
          mimeType = 'image/png'
        } else if (videoUrl.indexOf('.jpg') !== -1) {
          mimeType = 'image/jpg'
        } else if (videoUrl.indexOf('.jpeg') !== -1) {
          mimeType = 'image/jpeg'
        } else {
          //fall back to jpeg
          mimeType = 'image/jpeg'
        }
      }
    }

    if (!mimeType) {
      if (videoUrl.indexOf('.mp4') !== -1) {
        mimeType = 'video/mp4'
      } else if (videoUrl.indexOf('.gif') !== -1) {
        mimeType = 'image/gif'
      } else if (videoUrl.indexOf('.png') !== -1) {
        mimeType = 'image/png'
      } else if (videoUrl.indexOf('.jpg') !== -1) {
        mimeType = 'image/jpg'
      } else if (videoUrl.indexOf('.jpeg') !== -1) {
        mimeType = 'image/jpeg'
      } else {
        //fall back to jpeg
        mimeType = 'image/jpeg'
      }
    }
  } else {
    videoUrl = replaceIpfsIfNeeded(FRAMES_PLACEHOLDER_IPFS) //path to the placeholder
    mimeType = 'image/jpeg'
  }

  let fetcher = new Promise(resolve =>
    resolve({ width: 500, height: 500, failedDimensionDetection: true })
  )
  if (fetchDimensions) {
    fetcher = mimeType.startsWith('video')
      ? getVideoDimensionsOf(videoUrl)
      : mimeType.startsWith('image')
      ? getImageDimensionsOf(videoUrl)
      : new Promise(resolve =>
          resolve({ width: 500, height: 500, failedDimensionDetection: true })
        )
  }

  const dimensions = await fetcher

  const { width, height } = dimensions

  return {
    metadata: metadata,
    display_url: videoUrl,
    mimeType: mimeType,
    width: width,
    height: height,
    failedDimensionDetection: dimensions.failedDimensionDetection ? true : false
  }
}
