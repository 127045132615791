const getTileSvg = () => {
  return `<svg>
  <g id="Layer_8">
    <path d="M0 0h250v125H0z" class="prefix_main_colour"/>
    <defs>
      <path id="SVGID_00000149363143312460375810000013955760392428215971_" d="M0 0h250v125H0z"/>
    </defs>
    <clipPath id="SVGID_00000034066058194734718120000004337075278378447264_">
      <use xlink:href="#SVGID_00000149363143312460375810000013955760392428215971_" style="overflow:visible"/>
    </clipPath>
    <g style="clip-path:url(#SVGID_00000034066058194734718120000004337075278378447264_)">
      <linearGradient id="SVGID_1_" x1="125" x2="125" y1="125" y2="84.6667" gradientUnits="userSpaceOnUse">
        <stop offset="0" style="stop-color:#000"/>
        <stop offset=".1667" style="stop-color:#000;stop-opacity:0"/>
        <stop offset=".1803" style="stop-color:#000;stop-opacity:.0637"/>
        <stop offset=".381" style="stop-color:#000"/>
        <stop offset=".754" style="stop-color:#000"/>
        <stop offset=".873" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.5"/>
      </linearGradient>
      <path d="M0 84.67h250V125H0z" style="fill:url(#SVGID_1_)"/>
      <radialGradient id="SVGID_00000159440609067631896410000012451011927408012186_" cx="250" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="250" cy="104.83" r="15.09" style="fill:url(#SVGID_00000159440609067631896410000012451011927408012186_)"/>
      <radialGradient id="SVGID_00000103964160503522733090000014395198881296833954_" cx="0" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cy="104.83" r="15.09" style="fill:url(#SVGID_00000103964160503522733090000014395198881296833954_)"/>
      <radialGradient id="SVGID_00000060723522369525718490000005898202036277310629_" cx="31.25" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="31.25" cy="104.83" r="15.09" style="fill:url(#SVGID_00000060723522369525718490000005898202036277310629_)"/>
      <radialGradient id="SVGID_00000082331551960815713950000007472405702161783172_" cx="62.5" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="62.5" cy="104.83" r="15.09" style="fill:url(#SVGID_00000082331551960815713950000007472405702161783172_)"/>
      <radialGradient id="SVGID_00000091693710242084513110000015265345696365110953_" cx="93.75" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="93.75" cy="104.83" r="15.09" style="fill:url(#SVGID_00000091693710242084513110000015265345696365110953_)"/>
      <radialGradient id="SVGID_00000163790482366502112950000003420815607137245118_" cx="125" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="125" cy="104.83" r="15.09" style="fill:url(#SVGID_00000163790482366502112950000003420815607137245118_)"/>
      <radialGradient id="SVGID_00000031911553850816756420000010185258162237049003_" cx="156.25" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="156.25" cy="104.83" r="15.09" style="fill:url(#SVGID_00000031911553850816756420000010185258162237049003_)"/>
      <radialGradient id="SVGID_00000076602777623296306230000007974803825548942762_" cx="187.5" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="187.5" cy="104.83" r="15.09" style="fill:url(#SVGID_00000076602777623296306230000007974803825548942762_)"/>
      <radialGradient id="SVGID_00000094612522922691579110000008388343962499763613_" cx="218.75" cy="104.8333" r="15.0943" gradientUnits="userSpaceOnUse">
        <stop offset=".6138" style="stop-color:#000"/>
        <stop offset=".8281" style="stop-color:#030303;stop-opacity:.4452"/>
        <stop offset=".9784" style="stop-color:#0c0c0c;stop-opacity:.0559"/>
        <stop offset="1" style="stop-color:#0e0e0e;stop-opacity:0"/>
      </radialGradient>
      <circle cx="218.75" cy="104.83" r="15.09" style="fill:url(#SVGID_00000094612522922691579110000008388343962499763613_)"/>
      <circle cx="250" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="31.25" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="62.5" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="93.75" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="125" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="156.25" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="187.5" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <circle cx="218.75" cy="104.83" r="12.48" class="prefix_main_colour"/>
      <radialGradient id="SVGID_00000157283477514949636450000014569596572469878419_" cx="250" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="250" cy="104.83" r="12.48" style="fill:url(#SVGID_00000157283477514949636450000014569596572469878419_)"/>
      <radialGradient id="SVGID_00000127030162696188768910000012085045773874146485_" cx="0" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cy="104.83" r="12.48" style="fill:url(#SVGID_00000127030162696188768910000012085045773874146485_)"/>
      <radialGradient id="SVGID_00000072972256343562823200000006323241055301890981_" cx="31.25" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="31.25" cy="104.83" r="12.48" style="fill:url(#SVGID_00000072972256343562823200000006323241055301890981_)"/>
      <radialGradient id="SVGID_00000089551250663957925640000007131211197069505462_" cx="62.5" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="62.5" cy="104.83" r="12.48" style="fill:url(#SVGID_00000089551250663957925640000007131211197069505462_)"/>
      <radialGradient id="SVGID_00000019654798030179523490000017358575113635327674_" cx="93.75" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="93.75" cy="104.83" r="12.48" style="fill:url(#SVGID_00000019654798030179523490000017358575113635327674_)"/>
      <radialGradient id="SVGID_00000147905004087974521620000008747539210747168922_" cx="125" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="125" cy="104.83" r="12.48" style="fill:url(#SVGID_00000147905004087974521620000008747539210747168922_)"/>
      <radialGradient id="SVGID_00000157989359121199848510000000033296936360009402_" cx="156.25" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="156.25" cy="104.83" r="12.48" style="fill:url(#SVGID_00000157989359121199848510000000033296936360009402_)"/>
      <radialGradient id="SVGID_00000057844767299926815780000015960735773052032180_" cx="187.5" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="187.5" cy="104.83" r="12.48" style="fill:url(#SVGID_00000057844767299926815780000015960735773052032180_)"/>
      <radialGradient id="SVGID_00000029023617824031069230000013548083074291763627_" cx="218.75" cy="104.8333" r="12.4778" gradientUnits="userSpaceOnUse">
        <stop offset=".3915" style="stop-color:#000;stop-opacity:0"/>
        <stop offset="1" style="stop-color:#000;stop-opacity:.7"/>
      </radialGradient>
      <circle cx="218.75" cy="104.83" r="12.48" style="fill:url(#SVGID_00000029023617824031069230000013548083074291763627_)"/>
      <linearGradient id="SVGID_00000141432478728701534910000010911547773208059289_" x1="125" x2="125" y1="84.6667" y2="0" gradientUnits="userSpaceOnUse">
        <stop offset=".1085" style="stop-color:#000;stop-opacity:.4"/>
        <stop offset=".2963" style="stop-color:#000;stop-opacity:0"/>
        <stop offset=".8078" style="stop-color:#000;stop-opacity:.618"/>
        <stop offset=".8757" style="stop-color:#000;stop-opacity:.7"/>
        <stop offset=".9444" style="stop-color:#000;stop-opacity:.3774"/>
        <stop offset=".9841" style="stop-color:#000"/>
      </linearGradient>
      <path d="M0 0h250v84.67H0z" style="fill:url(#SVGID_00000141432478728701534910000010911547773208059289_)"/>
    </g>
    <linearGradient id="SVGID_00000012434193220535982550000007535304483415324597_" x1="125" x2="125" y1="8.3333" y2="0" gradientUnits="userSpaceOnUse">
      <stop offset=".2593" style="stop-color:#fff;stop-opacity:0"/>
      <stop offset=".5291" style="stop-color:#fff;stop-opacity:.5"/>
      <stop offset=".9788" style="stop-color:#fff;stop-opacity:0"/>
    </linearGradient>
    <path d="M0 0h250v8.33H0z" style="fill:url(#SVGID_00000012434193220535982550000007535304483415324597_)"/>
    <linearGradient id="SVGID_00000083791586758047443490000009316050380054308029_" x1="125" x2="125" y1="93.3333" y2="85" gradientUnits="userSpaceOnUse">
      <stop offset=".2593" style="stop-color:#fff;stop-opacity:0"/>
      <stop offset=".5291" style="stop-color:#fff;stop-opacity:.5"/>
      <stop offset=".9788" style="stop-color:#fff;stop-opacity:0"/>
    </linearGradient>
    <path d="M0 85h250v8.33H0z" style="fill:url(#SVGID_00000083791586758047443490000009316050380054308029_)"/>
    <linearGradient id="SVGID_00000059282184219673015390000007085705381281553318_" x1="125" x2="125" y1="124.2222" y2="118.8333" gradientUnits="userSpaceOnUse">
      <stop offset=".2593" style="stop-color:#fff;stop-opacity:0"/>
      <stop offset=".5291" style="stop-color:#fff;stop-opacity:.5"/>
      <stop offset=".9788" style="stop-color:#fff;stop-opacity:0"/>
    </linearGradient>
    <path d="M0 118.83h250v5.39H0z" style="fill:url(#SVGID_00000059282184219673015390000007085705381281553318_)"/>
    <radialGradient id="SVGID_00000030444335836459528250000014520180893185387914_" cx="4.1111" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="4.11" cy="100.11" r="4.11" style="fill:url(#SVGID_00000030444335836459528250000014520180893185387914_)"/>
    <radialGradient id="SVGID_00000033331045299400880840000011759150004036675992_" cx="35.4286" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="35.43" cy="100.11" r="4.11" style="fill:url(#SVGID_00000033331045299400880840000011759150004036675992_)"/>
    <radialGradient id="SVGID_00000153706812863056560280000001439312154620653755_" cx="66.746" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="66.75" cy="100.11" r="4.11" style="fill:url(#SVGID_00000153706812863056560280000001439312154620653755_)"/>
    <radialGradient id="SVGID_00000023274640089145843670000015032381643210922403_" cx="98.0635" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="98.06" cy="100.11" r="4.11" style="fill:url(#SVGID_00000023274640089145843670000015032381643210922403_)"/>
    <radialGradient id="SVGID_00000016774104152305002640000017998122597103069591_" cx="129.381" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="129.38" cy="100.11" r="4.11" style="fill:url(#SVGID_00000016774104152305002640000017998122597103069591_)"/>
    <radialGradient id="SVGID_00000046331619443822741930000000261918443057676716_" cx="160.6984" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="160.7" cy="100.11" r="4.11" style="fill:url(#SVGID_00000046331619443822741930000000261918443057676716_)"/>
    <radialGradient id="SVGID_00000168810752405423144070000016127582243232846993_" cx="192.0159" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="192.02" cy="100.11" r="4.11" style="fill:url(#SVGID_00000168810752405423144070000016127582243232846993_)"/>
    <radialGradient id="SVGID_00000138574172951391611080000003837621313899958414_" cx="223.3333" cy="100.1111" r="5.8373" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color:#fff"/>
      <stop offset=".0923" style="stop-color:#fff;stop-opacity:.8829"/>
      <stop offset=".7884" style="stop-color:#fff;stop-opacity:0"/>
    </radialGradient>
    <circle cx="223.33" cy="100.11" r="4.11" style="fill:url(#SVGID_00000138574172951391611080000003837621313899958414_)"/>
  </g>
</svg>`
}

export default getTileSvg