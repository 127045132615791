import React from 'react'
import './Loading.css'
import FRAME from '../../../assets/images/logos/FRAME_small.png'
import styled from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
const Logo = styled.img`
  flex-shrink: 1;
  flex: 1;
  display: block;
  min-height: 0;
  min-width: 0;
  margin: auto auto;
  object-fit: contain;
`

const Container = styled.div`
  flex: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  align-content: center;
`

export default function LoadingAnimation (props) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Container isMobile={isMobile}>
      <Logo className='framesLoader animated' src={FRAME} alt='Frames logo' />
    </Container>
  )
}
