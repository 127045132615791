import React from 'react'
import Skeleton from '@mui/material/Skeleton'

import { Box } from '@mui/material'

export default function NftLoadingView () {
  return (
    <Box
      sx={{
        height: 0,
        overflow: 'hidden',
        paddingTop: '100%',
        position: 'relative'
      }}
    >
      <Skeleton
        variant='rectangular'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      />
    </Box>
  )
}
